export enum AppAction {
  SetIsDarkTheme = 'SET_IS_DARK_THEME',
  SetShowProjectOverlay = 'SET_SHOW_PROJECT_OVERLAY',
  SetActiveProject = 'SET_ACTIVE_PROJECT',
  SetIsLoading = 'SET_IS_LOADING'
}

interface IAppState {
  isDarkTheme: boolean;
  showProjectOverlay: boolean;
  activeProject: string;
  isLoading: boolean;
}

export interface IAppAction {
  type: AppAction;
  payload?: string | boolean;
  callback?: (state?: IAppState) => {} | void;
}

export const INITIAL_APP_STATE: IAppState = {
  isDarkTheme: false,
  showProjectOverlay: false,
  activeProject: '',
  isLoading: false
};

export const appReducer = (state: IAppState, action: IAppAction): IAppState => {
  switch (action.type) {
    case 'SET_IS_DARK_THEME':
      return { ...state, isDarkTheme: action.payload as boolean };
    case 'SET_SHOW_PROJECT_OVERLAY':
      return { ...state, showProjectOverlay: action.payload as boolean };
    case 'SET_ACTIVE_PROJECT':
      return { ...state, activeProject: action.payload as string };
    case 'SET_IS_LOADING':
      return { ...state, isLoading: action.payload as boolean };
    default:
      return state;
  }
};
