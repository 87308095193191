import styled from '@emotion/styled';
import React from 'react';

const FooterContainer = styled.div({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    width: '100%',
    backgroundColor: 'rgba(20,20,20,1)',

    a: {
        color: '#0dcaf0'
    }
});

export const Footer: React.FC = () => {
    return <FooterContainer>
        <p style={{color: 'white', margin: 0, fontSize: '1.5em', paddingTop: '20px', paddingBottom: '10px'}}>CREATED BY PONTUS RIPCRAZE VIRUS</p>
        <span style={{color: 'white', fontSize: '0.7em', margin: 0, paddingBottom: '30px'}}>*This website is not in any way associated with Entropia Universe or Mindark, it is entirely fan made for the purpose of helping players.</span>
        <span style={{color: 'white', fontSize: '1em', margin: 0, paddingBottom: '30px'}}>For contact or feedback please send an email to <a href="mailto: admin@ripcraze.com">admin@ripcraze.com</a></span>
    </FooterContainer>
}