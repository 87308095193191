import styled from '@emotion/styled';
import React, { useContext } from 'react';
import { AppConfigContext } from '../../config/AppConfigContext';
import { setCookie } from '../../hooks/useCookies';
import { ActionButton } from '../buttons/ActionButton';

const Container = styled.div<{}>(({ theme }: any) => ({
  position: 'fixed',
  bottom: 0,
  width: '100%',
  minHeight: '50px',
  backgroundColor: 'rgba(80, 100, 80, 0.3)',

  [theme.breakpoints.tabletAndLower]: {
    paddingTop: '20px'
  }
}));

export const Cookies: React.FC = () => {
  const appConfigContext = useContext(AppConfigContext);

  return <Container>
    <span>By using this website you consent to the usage of cookies used for personalization of the site.</span>
    <ActionButton inline={true} onClick={() => {
      setCookie("consent", "true", 365);
      appConfigContext.cookies.consent = true;
    }}>Ok</ActionButton>
  </Container>
}