import React, { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { readCookieByName, setCookie } from '../../../hooks/useCookies';
import { DailyMissionPlanet } from '../../../models/ToolsEnum';

enum MissionType {
    Bonus,
    Silver,
    Normal
}

export const DailyHunt1List = [
    { Amount: 10, Name: 'Chirpy (20 Token Bonus)', MinHp: 10, Type: MissionType.Bonus, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=17" },
    { Amount: 80, Name: 'Merp Survival (2 Tokens)', MinHp: 50, Type: MissionType.Silver, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=49" },
    { Amount: 40, Name: 'Repesilex', MinHp: 90, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=58" },
    { Amount: 40, Name: 'Trilomite', MinHp: 190, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=72" },
    { Amount: 50, Name: 'Chirpy', MinHp: 10, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=17" },
    { Amount: 50, Name: 'Combibo', MinHp: 80, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=18" },
    { Amount: 50, Name: 'Cornundos', MinHp: 150, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=20" },
    { Amount: 50, Name: 'Cornundacauda', MinHp: 150, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=19" },
    { Amount: 50, Name: 'Diripi', MinHp: 40, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=22" },
    { Amount: 50, Name: 'Faucervix', MinHp: 100, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=28" },
    { Amount: 50, Name: 'Fugabarba', MinHp: 10, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=33" },
    { Amount: 50, Name: 'Gibnib', MinHp: 10, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=35" },
    { Amount: 50, Name: 'Gradivore', MinHp: 100, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=37" },
    { Amount: 50, Name: 'Hiryuu', MinHp: 120, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=38" },
    { Amount: 50, Name: 'Igni', MinHp: 40, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=41" },
    { Amount: 50, Name: 'Kerberos', MinHp: 110, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=114" },
    { Amount: 50, Name: 'Molisk', MinHp: 160, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=50" },
    { Amount: 50, Name: 'Morsusilex', MinHp: 190, Link: "https://www.entropiawiki.com/Info.aspx?chart=Mob&id=51" },
    { Amount: 50, Name: 'Mourner', MinHp: 120, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=52" },
    { Amount: 50, Name: 'Prancer', MinHp: 70, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=56" },
    { Amount: 50, Name: 'Snarksnot', MinHp: 70, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=66" },
    { Amount: 50, Name: 'Tantillion', MinHp: 50, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=68" },
    { Amount: 50, Name: 'Traeskeron', MinHp: 180, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=295" },
    { Amount: 50, Name: 'Tripudon', MinHp: 150, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=73" },
    { Amount: 50, Name: 'Turp', MinHp: 10, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=75" },
    { Amount: 80, Name: 'Foul', MinHp: 100, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=32" },
    { Amount: 80, Name: 'Merp', MinHp: 50, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=49" },
    { Amount: 80, Name: 'Plumatergus', MinHp: 40, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=55" },
    { Amount: 90, Name: 'Daikiba', MinHp: 50, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=21" },
    { Amount: 90, Name: 'Shinkiba', MinHp: 30, Link: "https://www.entropiawiki.com/Info.aspx?chart=Mob&id=222" },
    { Amount: 100, Name: 'Exarosaur', MinHp: 50, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=26" },
    { Amount: 100, Name: 'Rippersnapper', MinHp: 50, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=59" },
    { Amount: 100, Name: 'Sabakuma', MinHp: 20, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=60" },
    { Amount: 100, Name: 'Snablesnot Female', MinHp: 40, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=63" },
    { Amount: 100, Name: 'Snablesnot Male', MinHp: 20, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=64" },
]

export const DailyHunt2List = [
    { Amount: 10, Name: '10 Gibnib (20 Token Bonus)', MinHp: 10, Type: MissionType.Bonus, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=35" },
    { Amount: 50, Name: 'Mulmun (4 Tokens)', MinHp: 510, Type: MissionType.Silver, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=102" },
    { Amount: 100, Name: 'Attacker (4 Tokens)', MinHp: 200, Type: MissionType.Silver, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=101" },
    { Amount: 20, Name: 'Sonic Squid', MinHp: 660, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=136" },
    { Amount: 30, Name: 'Tantardion', MinHp: 560, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=67" },
    { Amount: 50, Name: 'Calypsocod', MinHp: 240, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=134" },
    { Amount: 50, Name: 'Defender', MinHp: 560, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=88" },
    { Amount: 50, Name: 'Drone Coordinator', MinHp: 390, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=87" },
    { Amount: 50, Name: 'Equus', MinHp: 280, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=24" },
    { Amount: 50, Name: 'Fungoid', MinHp: 450, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=34" },
    { Amount: 50, Name: 'Mermoth', MinHp: 320, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=48" },
    { Amount: 50, Name: 'Mind Reaver', MinHp: 640, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=449" },
    { Amount: 50, Name: 'Miner Bot', MinHp: 550, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=110" },
    { Amount: 50, Name: 'Scaboreas', MinHp: 590, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=61" },
    { Amount: 50, Name: 'Snarg', MinHp: 320, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=65" },
    { Amount: 60, Name: 'Nexnecis', MinHp: 450, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=95" },
    { Amount: 80, Name: 'Boorum Female', MinHp: 490, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=100" },
    { Amount: 80, Name: 'Boorum Male', MinHp: 550, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=99" },
    { Amount: 80, Name: 'Bristlehog', MinHp: 280, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=12" },
    { Amount: 80, Name: 'Gokibusagi', MinHp: 280, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=36" },
    { Amount: 80, Name: 'Harbinger', MinHp: 450, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=176" },
    { Amount: 80, Name: 'Marcimex', MinHp: 780, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=451" },
    { Amount: 80, Name: 'Warrior', MinHp: 500, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=78" },
    { Amount: 100, Name: 'Argonaut', MinHp: 300, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=5" },
    { Amount: 100, Name: 'Armax Bull', MinHp: 410, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=6" },
    { Amount: 100, Name: 'Armax Cow', MinHp: 230, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=7" },
    { Amount: 100, Name: 'Atrax', MinHp: 270, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=8" },
    { Amount: 100, Name: 'Droka', MinHp: 480, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=121" },
    { Amount: 100, Name: 'Drone', MinHp: 300, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=23" },
    { Amount: 100, Name: 'Thorifoid Berserker', MinHp: 420, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=71" },
    { Amount: 100, Name: 'Thorifoid Shaman', MinHp: 400, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=70" },
    { Amount: 100, Name: 'Thorio Female', MinHp: 200, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=81" },
    { Amount: 100, Name: 'Thorio Male', MinHp: 300, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=79" },
    { Amount: 100, Name: 'Trooper', MinHp: 410, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=74" },
];

export const DailyHunt3List = [
    { Amount: 10, Name: 'Fugabarba (20 Token Bonus)', MinHp: 10, Type: MissionType.Bonus, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=33" },
    { Amount: 50, Name: 'Muluk-Hir (6 Tokens)', MinHp: 970, Type: MissionType.Silver, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=103" },
    { Amount: 50, Name: 'Mulaak\'f (6 Tokens)', MinHp: 1330, Type: MissionType.Silver, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=105" },
    { Amount: 40, Name: 'Aetherex', MinHp: 600, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=1" },
    { Amount: 40, Name: 'Grand Formidon', MinHp: 1750, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=131" },
    { Amount: 40, Name: 'Second Entity', MinHp: 1810, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=62" },
    { Amount: 40, Name: 'Vanguard', MinHp: 2000, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=438" },
    { Amount: 50, Name: 'Aurli', MinHp: 1530, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=10" },
    { Amount: 50, Name: 'Caperon', MinHp: 1340, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=90" },
    { Amount: 50, Name: 'Cersumon', MinHp: 800, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=16" },
    { Amount: 50, Name: 'Chomper', MinHp: 2110, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=96" },
    { Amount: 50, Name: 'Cornoanterion', MinHp: 930, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=85" },
    { Amount: 50, Name: 'Formidon', MinHp: 1100, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=31" },
    { Amount: 50, Name: 'Frescoquda', MinHp: 1380, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=130" },
    { Amount: 50, Name: 'Furor', MinHp: 1030, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=125" },
    { Amount: 50, Name: 'Globster', MinHp: 2230, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=92" },
    { Amount: 50, Name: 'Hispidus', MinHp: 780, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=39" },
    { Amount: 50, Name: 'Hogglo', MinHp: 2000, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=40" },
    { Amount: 50, Name: 'Kreltin', MinHp: 1170, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=45" },
    { Amount: 50, Name: 'Letomie', MinHp: 1530, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=129" },
    { Amount: 50, Name: 'Malcruentor', MinHp: 2570, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=109" },
    { Amount: 50, Name: 'Phasm', MinHp: 1870, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=98" },
    { Amount: 50, Name: 'Sumima', MinHp: 1450, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&name=Sumima_(Calypso)" },
    { Amount: 50, Name: 'TskTsk', MinHp: 730, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=106" },
    { Amount: 50, Name: 'Razortooth', MinHp: 850, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=135" },
    { Amount: 60, Name: 'Ambulimax', MinHp: 1010, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=3" },
    { Amount: 60, Name: 'Falxangius', MinHp: 2670, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=27" },
    { Amount: 60, Name: 'Formicacida', MinHp: 1200, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=30" },
    { Amount: 60, Name: 'Kingfisher', MinHp: 1560, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=44" },
    { Amount: 70, Name: 'Legionnaire', MinHp: 810, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=122" },
    { Amount: 70, Name: 'Osseocollum', MinHp: 3450, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=97" },
    { Amount: 80, Name: 'Feffox', MinHp: 1400, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=445" },
    { Amount: 100, Name: 'Atrox', MinHp: 990, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=9" },
    { Amount: 100, Name: 'Aurli Broods (avg)', MinHp: 2020, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=473" },
    { Amount: 100, Name: 'Estophyl', MinHp: 700, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=25" },
    { Amount: 100, Name: 'Eviscerator', MinHp: 920, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=120" },
    { Amount: 100, Name: 'Itumatrox', MinHp: 680, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=42" },
    { Amount: 100, Name: 'Leviathan', MinHp: 2650, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=133" },
    { Amount: 100, Name: 'Maffoid', MinHp: 650, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=47" },
    { Amount: 100, Name: 'Mind Reaver', MinHp: 640, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=449" },
    { Amount: 100, Name: 'Neconu', MinHp: 900, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=53" },
    { Amount: 100, Name: 'Oculus', MinHp: 900, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=54" },
    { Amount: 100, Name: 'Proteron', MinHp: 2910, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=132" },
    { Amount: 100, Name: 'Rextelum', MinHp: 1580, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&name=Rextelum" },
    { Amount: 100, Name: 'Scipulor', MinHp: 1660, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=107" },
    { Amount: 100, Name: 'Primordial Longu', MinHp: 620, Link: "http://www.entropiawiki.com/Info.aspx?chart=Mob&id=425" },
];

export const DailyCraftList = [
    { Amount: 51, Name: 'Basic Screws (10 Token Bonus)', Value: 0.39, Type: MissionType.Bonus, Link: "http://www.entropiawiki.com/Info.aspx?chart=Blueprint&id=256" },
    { Amount: 36, Name: 'Finder F-101', Value: 1.11, Type: MissionType.Silver, Link: "http://www.entropiawiki.com/Info.aspx?chart=Blueprint&id=361" },
    { Amount: 43, Name: 'Jester D-1', Value: 0.94, Type: MissionType.Silver, Link: "http://www.entropiawiki.com/Info.aspx?chart=Blueprint&id=404" },
    { Amount: 8, Name: 'Level 1 Finder Amplifier Light (L)', Value: 4.94, Link: "http://www.entropiawiki.com/Info.aspx?chart=Blueprint&id=598" },
    { Amount: 21, Name: 'Armor Defense Enhancer I', Value: 1.9, Link: "http://www.entropiawiki.com/Info.aspx?chart=Blueprint&id=1490" },
    { Amount: 27, Name: 'Basic Pattern Pants', Value: 1.5, Link: "http://www.entropiawiki.com/Info.aspx?chart=Blueprint&id=281" },
    { Amount: 29, Name: 'Pall Stool', Value: 1.4, Link: "http://www.entropiawiki.com/Info.aspx?chart=Blueprint&id=492" },
    { Amount: 53, Name: 'Settler Arm Guards', Value: 0.75, Link: "http://www.entropiawiki.com/Info.aspx?chart=Blueprint&id=40" },
    { Amount: 87, Name: 'Combustor', Value: 0.46, Link: "http://www.entropiawiki.com/Info.aspx?chart=Blueprint&id=1561" },
    { Amount: 111, Name: 'Metal Plating', Value: 0.36, Link: "http://www.entropiawiki.com/Info.aspx?chart=Blueprint&id=691" },
    { Amount: 286, Name: 'Generic Leather Texture', Value: 0.07, Link: "http://www.entropiawiki.com/Info.aspx?chart=Blueprint&id=1083" },
];

export const DailyMinerList = [
    { Amount: 2500, Name: '2500 Lyst/Oil (20 Token Bonus)', Value: 0.01, Type: MissionType.Bonus, Link: "http://www.entropiawiki.com/Info.aspx?chart=Material&id=35" },
    { Amount: 500, Name: 'Garcen Grease', Value: 0.1, Type: MissionType.Silver, Link: "http://www.entropiawiki.com/Info.aspx?chart=Material&id=10" },
    { Amount: 1000, Name: 'Alicenies Liquid', Value: 0.05, Type: MissionType.Silver, Link: "http://www.entropiawiki.com/Info.aspx?chart=Material&id=5" },
    { Amount: 2500, Name: 'Belkar Stone', Value: 0.02, Type: MissionType.Silver, Link: "http://www.entropiawiki.com/Info.aspx?chart=Material&id=19" },
    { Amount: 200, Name: 'Magerian Mist', Value: 0.25, Link: "http://www.entropiawiki.com/Info.aspx?chart=Material&id=13" },
    { Amount: 263, Name: 'Lytairian Dust', Value: 0.19, Link: "http://www.entropiawiki.com/Info.aspx?chart=Material&id=12" },
    { Amount: 294, Name: 'Caldorite Stone', Value: 0.17, Link: "http://www.entropiawiki.com/Info.aspx?chart=Material&id=21" },
    { Amount: 313, Name: 'Copper Stone', Value: 0.16, Link: "http://www.entropiawiki.com/Info.aspx?chart=Material&id=23" },
    { Amount: 333, Name: 'Cumbriz Stone', Value: 0.15, Link: "http://www.entropiawiki.com/Info.aspx?chart=Material&id=24" },
    { Amount: 333, Name: 'Typonolic Steam', Value: 0.15, Link: "http://www.entropiawiki.com/Info.aspx?chart=Material&id=16" },
    { Amount: 385, Name: 'Iron Stone', Value: 0.13, Link: "http://www.entropiawiki.com/Info.aspx?chart=Material&id=32" },
    { Amount: 417, Name: 'Frigulite Stone', Value: 0.12, Link: "http://www.entropiawiki.com/Info.aspx?chart=Material&id=28" },
    { Amount: 500, Name: 'Zinc Stone', Value: 0.1, Link: "http://www.entropiawiki.com/Info.aspx?chart=Material&id=47" },
    { Amount: 625, Name: 'Narcanisum Stone', Value: 0.08, Link: "http://www.entropiawiki.com/Info.aspx?chart=Material&id=38" },
    { Amount: 1250, Name: 'Blausariam Stone', Value: 0.04, Link: "http://www.entropiawiki.com/Info.aspx?chart=Material&id=20" },
    { Amount: 2500, Name: 'Melchi Water', Value: 0.02, Link: "http://www.entropiawiki.com/Info.aspx?chart=Material&id=14" },
    { Amount: 5000, Name: 'Force Nexus', Value: 0.01, Link: "http://www.entropiawiki.com/Info.aspx?chart=Material&id=9" },
    { Amount: 5000, Name: 'Lysterium Stone', Value: 0.01, Link: "http://www.entropiawiki.com/Info.aspx?chart=Material&id=35" },
    { Amount: 5000, Name: 'Cruide Oil', Value: 0.01, Link: "http://www.entropiawiki.com/Info.aspx?chart=Material&id=15" },
];

export const DailyFeffoid = [
    '- Kill 15 Feffoids and collect Crystal',
    '- Approximate cost 35 ped',
    '- Quest Location: Ms.Leyana (61327, 75263)',
    '- Cave Location: (61130, 76401)',
];

export const DailyArgo = [
    '- Kill 50 Argonauts and collect 5 equipments',
    '- Approximate cost 55 ped',
    '- Quest Location: (62719, 676117)',
    '- Cave Location: Cave Nearby'
];

export const DailyJinn = [
    '- Collect 50 Blakkshaah Badges',
    '- Quest Location: Fort Fury',
    '- Feffoid camp: /wp [Calypso, 67540, 78305, 310, Waypoint]'
];

export const DailyBloodclaw = [
    '- Kill Blooodclaw (L129)',
    '- Quest Location: Colonel Rhodes @ Camp Echidna',
    '- Bloodclaw Location: North part of Containment Zone East of Aegis Mound'
];

const Main = styled.div({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    backgroundColor: 'rgba(20,20,20,1)',
    color: 'white'
});

const DailyTokenContainer = styled.div<{}>(({ theme }: any) => ({
    maxWidth: '50%',
    minWidth: '50%',
    margin: '0 auto',

    [theme.breakpoints.tabletAndLower]: {
        maxWidth: '85%',
        minWidth: '85%',
    },

    [theme.breakpoints.mobileAndLower]: {
        maxWidth: '95%',
        minWidth: '95%',
    }
}));

const DailyTokenTitle = styled.h2({
    marginTop: '20px',
    marginBottom: '0px'
})

const DailyTokenList = styled.ul({
    padding: 0,
    margin: 0
})

const DailyTokenRow = styled.li({
    textDecoration: 'none',
    listStyle: 'none',
    marginBottom: '1rem'
});

const DailyTokenRowHeader = styled.div<{ isOpen: boolean }>(({ isOpen }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',

    '&:hover': {
        cursor: 'pointer'
    },

    '.dailyTimer': {
        //marginLeft: 'auto',
        minWidth: '50%',
        marginRight: '5px',
        textShadow: '2px 2px 4px #000000'
    },

    '.dailyName': {
        minWidth: '150px',
        width: '50%',
        textAlign: 'start',
        textShadow: '2px 2px 4px #000000'
    }
}));

const DailyTokenRowContent = styled.div<{ show: boolean }>(({ show }) => ({
    height: show ? '100%' : 0,
    display: 'flex',
    justifyContent: 'left',
    flexDirection: 'column',
    textAlign: 'left',
    marginBottom: show ? '15px' : 0,
    overflow: 'hidden'
}));

const DailyTable = styled.table({
    borderCollapse: 'collapse',
    border: '1px solid white',
    borderTop: 'none',
    padding: '10px',
    textAlign: 'left',

    'td, th': {
        padding: '5px'
    },

    'th': {
        fontWeight: 'normal',
        borderBottom: '1px solid white',

        ':hover': {
            cursor: 'pointer'
        }
    },

    'a': {
        padding: 0
    }
});

const DailyTextQuest = styled.div({
    height: '100%',
    display: 'flex',
    justifyContent: 'left',
    flexDirection: 'column',
    textAlign: 'left',
    overflow: 'hidden',
    border: '1px solid white',
    borderTop: 'none',
    padding: '10px',
});

const MissionTableRow = styled.tr<{ isBonus?: boolean, isSilver?: boolean }>(({ isBonus, isSilver }) => ({
    backgroundColor: isBonus ? 'rgba(191, 191, 63, 0.4)' : isSilver ? 'rgba(165, 165, 165, 0.4)' : 'inherit'
}));

const MissionBar = styled.div({
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    height: '2em',
    backgroundColor: 'transparent',
    border: '1px solid white',
    borderRadius: '5px',
    zIndex: 1
});

const MissionBarProgress = styled.div<{ percentToFinish?: number }>(({ percentToFinish }) => ({
    position: 'absolute',
    backgroundColor: percentToFinish === 0 ? '#2f412f' : '#9b3d4f',
    height: '2rem',
    width: percentToFinish === 0 ? '99.9%' : percentToFinish + 'px',
    zIndex: 0,
    borderRadius: '5px',
    opacity: '40%'
}));

const MissionBarInfo = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    flexGrow: 1,
    backgroundColor: 'transparent',
    zIndex: 2,
    margin: 'auto 0.5em auto 0.5em'
});

const Checkbox = styled.input({
    appearance: 'none',
    width: '20px',
    height: '20px',
    backgroundColor: '#0a0f1a',
    border: '2px solid #0f51b8',
    cursor: 'pointer',
    borderRadius: '3px',
    position: 'relative',
    outline: 'none',

    ':checked': {
        backgroundColor: '#0f51b8',

        ':after': {
            content: "''",
            position: 'absolute',
            left: '5px',
            top: '1px',
            width: '4px',
            height: '9px',
            border: 'solid #fff',
            borderWidth: '0 3px 3px 0',
            transform: 'rotate(45deg)'
        }
    },

    ':hover': {
        borderColor: '#1bd1a5'
    },

    ':focused': {
        boxShadow: '0 0 0 2px rgba(27,209,165,0.5)'
    }
});

const Loading = styled.div({
    margin: '2em auto',
    border: '4px solid #0f51b8',
    borderTop: '4px solid #333',
    borderRadius: '50%',
    width: '50px',
    height: '50px',
    animation: 'spin 1s linear infinite',

    '@keyframes spin': {
        '0%': { transform: 'rotate(0deg)' },
        '100%': { transform: 'rotate(360deg)' }
    }
});

// const PlanetList = styled.div({
//     display: 'flex',
//     flexDirection: 'row',
//     justifyContent: 'space-evenly',
//     maxWidth: '200px',
//     margin: '0 auto',

//     'div': {
//         paddingTop: '10px',
//         fontSize: 16,
//         textDecoration: 'none',
//         color: 'white',

//         '&:hover': {
//             cursor: 'pointer',
//             textDecoration: 'underline'
//         }
//     }
// });

export const DailyMissions: React.FC = () => {
    const timePrefix = 'AVAILABLE';
    const [selectedPlanet] = useState(DailyMissionPlanet.Calypso);
    const [showDailyContent, setShowDailyContent] = useState(0);
    const [t1, setT1] = useState(Number(readCookieByName('t1')) > 0 ? Number(readCookieByName('t1')) : 0);
    const [t2, setT2] = useState(Number(readCookieByName('t2')) > 0 ? Number(readCookieByName('t2')) : 0);
    const [t3, setT3] = useState(Number(readCookieByName('t3')) > 0 ? Number(readCookieByName('t3')) : 0);
    const [t4, setT4] = useState(Number(readCookieByName('t4')) > 0 ? Number(readCookieByName('t4')) : 0);
    const [t5, setT5] = useState(Number(readCookieByName('t5')) > 0 ? Number(readCookieByName('t5')) : 0);
    const [t6, setT6] = useState(Number(readCookieByName('t6')) > 0 ? Number(readCookieByName('t6')) : 0);
    const [t7, setT7] = useState(Number(readCookieByName('t7')) > 0 ? Number(readCookieByName('t7')) : 0);
    const [t8, setT8] = useState(Number(readCookieByName('t8')) > 0 ? Number(readCookieByName('t8')) : 0);
    const [t9, setT9] = useState(Number(readCookieByName('t9')) > 0 ? Number(readCookieByName('t9')) : 0);
    const [t1Text, setT1Text] = useState('');
    const [t2Text, setT2Text] = useState('');
    const [t3Text, setT3Text] = useState('');
    const [t4Text, setT4Text] = useState('');
    const [t5Text, setT5Text] = useState('');
    const [t6Text, setT6Text] = useState('');
    const [t7Text, setT7Text] = useState('');
    const [t8Text, setT8Text] = useState('');
    const [t9Text, setT9Text] = useState('');
    const [t1Time, setT1Time] = useState(0);
    const [t2Time, setT2Time] = useState(0);
    const [t3Time, setT3Time] = useState(0);
    const [t4Time, setT4Time] = useState(0);
    const [t5Time, setT5Time] = useState(0);
    const [t6Time, setT6Time] = useState(0);
    const [t7Time, setT7Time] = useState(0);
    const [t8Time, setT8Time] = useState(0);
    const [t9Time, setT9Time] = useState(0);
    const [isLoaded, setIsLoaded] = useState(false);

    let tTimeList = [
        [t1, setT1, setT1Text, setT1Time], [t2, setT2, setT2Text, setT2Time], [t3, setT3, setT3Text, setT3Time],
        [t4, setT4, setT4Text, setT4Time], [t5, setT5, setT5Text, setT5Time], [t6, setT6, setT6Text, setT6Time],
        [t7, setT7, setT7Text, setT7Time], [t8, setT8, setT8Text, setT8Time], [t9, setT9, setT9Text, setT9Time]
    ];

    // CYRENE
    // const [imperium1, setImperium1] = useState(Number(readCookieByName('imperium1')) > 0 ? Number(readCookieByName('imperium1')) : 0);
    // const [imperium2, setImperium2] = useState(Number(readCookieByName('imperium2')) > 0 ? Number(readCookieByName('imperium2')) : 0);
    // const [imperium3, setImperium3] = useState(Number(readCookieByName('imperium3')) > 0 ? Number(readCookieByName('imperium3')) : 0);
    // const [imperium4, setImperium4] = useState(Number(readCookieByName('imperium4')) > 0 ? Number(readCookieByName('imperium4')) : 0);
    // const [imperium5, setImperium5] = useState(Number(readCookieByName('imperium5')) > 0 ? Number(readCookieByName('imperium5')) : 0);
    // const [imperium6, setImperium6] = useState(Number(readCookieByName('imperium6')) > 0 ? Number(readCookieByName('imperium6')) : 0);
    // const [imperium1Text, setImperium1Text] = useState('');
    // const [imperium2Text, setImperium2Text] = useState('');
    // const [imperium3Text, setImperium3Text] = useState('');
    // const [imperium4Text, setImperium4Text] = useState('');
    // const [imperium5Text, setImperium5Text] = useState('');
    // const [imperium6Text, setImperium6Text] = useState('');
    // const [imperium1Time, setImperium1Time] = useState(0);
    // const [imperium2Time, setImperium2Time] = useState(0);
    // const [imperium3Time, setImperium3Time] = useState(0);
    // const [imperium4Time, setImperium4Time] = useState(0);
    // const [imperium5Time, setImperium5Time] = useState(0);
    // const [imperium6Time, setImperium6Time] = useState(0);
    // let imperiumTimeList = [
    //     [imperium1, setImperium1, setImperium1Text, setImperium1Time], [imperium2, setImperium2, setImperium2Text, setImperium2Time], [imperium3, setImperium3, setImperium3Text, setImperium3Time],
    //     [imperium4, setImperium4, setImperium4Text, setImperium4Time], [imperium5, setImperium5, setImperium5Text, setImperium5Time], [imperium6, setImperium6, setImperium6Text, setImperium6Time]
    // ];

    //#region Tables
    const generateDailyHuntList = (type: number) => {
        var list: {
            Amount: number;
            Name: string;
            MinHp: number;
            Type?: MissionType;
            Link?: string;
        }[];

        switch (type) {
            case 1: list = DailyHunt1List;
                break;
            case 2: list = DailyHunt2List;
                break;
            case 3: list = DailyHunt3List;
                break;
            default: list = DailyHunt1List;
                break;
        }

        return list.map(x =>
            <MissionTableRow isBonus={x.Type === MissionType.Bonus} isSilver={x.Type === MissionType.Silver} key={x.Name}>
                <td>{x.Amount}</td>
                <td><a style={{ color: 'white' }} href={x.Link} target='_blank' rel='noopener noreferrer'>{x.Name}</a></td>
                <td>{x.MinHp}</td>
                <td>{(x.Amount * ((x.MinHp / 300) * 1.1)).toFixed(2)} PED</td>
            </MissionTableRow>
        );
    }

    const DailyHuntTable = (huntType: number) => {
        function SortTableColumn() {
            switch (huntType) {
                case 1:
                    DailyHunt1List.sort((a, b) => a.Amount - b.Amount);
                    generateDailyHuntList(1);
                    break;
            }
        }

        return <DailyTable>
            <tbody>
                <tr>
                    <th onClick={() => {
                        SortTableColumn();
                    }}>Kills</th>
                    <th>Name</th>
                    <th>MinHP</th>
                    <th>Est.Cost</th>
                </tr>
                {generateDailyHuntList(huntType)}
            </tbody>
        </DailyTable>
    }

    const generateCraftList = () => {
        return DailyCraftList.map(x =>
            <MissionTableRow isBonus={x.Type === MissionType.Bonus} isSilver={x.Type === MissionType.Silver} key={x.Name}>
                <td>{x.Amount}</td>
                <td><a style={{ color: 'white' }} href={x.Link} target='_blank' rel='noopener noreferrer'>{x.Name}</a></td>
                <td>{x.Value}</td>
                <td>{(x.Amount * x.Value).toFixed(2)}ped</td>
            </MissionTableRow>
        );
    }

    const DailyCraftTable = () => {
        return <DailyTable>
            <tbody>
                <tr>
                    <th>No.</th>
                    <th>Name</th>
                    <th>Cost/M (TT)</th>
                    <th>Tot Cost (TT)</th>
                </tr>
                {generateCraftList()}
            </tbody>
        </DailyTable>
    }

    const generateMiningList = () => {
        return DailyMinerList.map(x =>
            <MissionTableRow isBonus={x.Type === MissionType.Bonus} isSilver={x.Type === MissionType.Silver} key={x.Name}>
                <td>{x.Amount}</td>
                <td><a style={{ color: 'white' }} href={x.Link} target='_blank' rel='noopener noreferrer'>{x.Name}</a></td>
            </MissionTableRow>
        );
    }

    const DailyMiningTable = () => {
        return <DailyTable>
            <tbody>
                <tr>
                    <th>No.</th>
                    <th>Name</th>
                </tr>
                {generateMiningList()}
            </tbody>
        </DailyTable>
    }

    const generateDailyTextQuest = (title: string) => {
        var list = DailyFeffoid;

        switch (title) {
            case 'feffoid': list = DailyFeffoid;
                break;
            case 'argo': list = DailyArgo;
                break;
            case 'jinn': list = DailyJinn;
                break;
            case 'bloodclaw': list = DailyBloodclaw
                break;
        }

        return list.map(x => <span key={x}>{x}</span>);
    }
    //#endregion

    const setCountdown = (
        timerNumber: string,
        hours: number,
        e: React.ChangeEvent<HTMLInputElement>,
        value: React.Dispatch<React.SetStateAction<number>>) => {
        let d = new Date().getTime();
        let date = (d + (hours * 60 * 60 * 1000));

        if (e.target.checked) {
            setCookie(timerNumber, date.toString(), 7);
            value(date);
        } else {
            setCookie(timerNumber, '0', 7);
            value(0);
        }
    };

    const padTo2Digits = (num: number) => {
        return num.toString().padStart(2, '0');
    }

    const convertTimeRemaining = useCallback((milliseconds: number) => {
        let seconds = Math.floor(milliseconds / 1000);
        let minutes = Math.floor(seconds / 60);
        let hours = Math.floor(minutes / 60);

        seconds = seconds % 60;
        minutes = minutes % 60;
        hours = hours % 24;

        return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`;
    }, []);

    const setCompletedMissionTimer = (setTimer: React.Dispatch<React.SetStateAction<number>>, setTimerText: React.Dispatch<React.SetStateAction<string>>, timerNumber: string) => {
        setCookie(timerNumber, '0', 7);
        setTimer(0);
        setTimerText(': NOW');
    };

    useEffect(() => {
        const interval = setInterval(() => {
            if (!isLoaded) {
                setIsLoaded(true);
            }

            let currentTime = new Date().getTime();

            for (let i = 0; i < tTimeList.length; i++) {
                let tTime = tTimeList[i][0] as number;
                calcTimeLeft(i, tTime);

                switch (i) {
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 6:
                    case 7:
                        calcCountdownBarWidth(i, 16, tTime);
                        break;
                    case 5:
                        calcCountdownBarWidth(i, 20, tTime);
                        break;
                    case 8:
                        calcCountdownBarWidth(i, 23, tTime);
                        break;
                }
            }

            function calcTimeLeft(i: number, tTime: number) {
                let t = tTime - currentTime;
                let setT = tTimeList[i][1] as React.Dispatch<React.SetStateAction<number>>;
                let setTText = tTimeList[i][2] as React.Dispatch<React.SetStateAction<string>>;

                t > 0
                    ? setTText(" IN: " + convertTimeRemaining(t))
                    : setCompletedMissionTimer(setT, setTText, 't' + (i + 1));
            }

            function calcCountdownBarWidth(i: number, baseHours: number, tTime: number) {
                let setT = tTimeList[i][3] as React.Dispatch<React.SetStateAction<number>>;
                const totalMillisecondsFor100Percent = baseHours * 3600000;
                const percentage = ((tTime - currentTime) / totalMillisecondsFor100Percent) * 100;

                const parent = document.querySelector('.missionbar') as HTMLElement;
                if (parent) {
                    const parentWidth = parent.offsetWidth;
                    const childWidth = (parentWidth * (percentage / 100));

                    tTime === 0
                        ? setT(0)
                        : setT(childWidth > 0 ? (childWidth - 2) : childWidth);
                }
            }
        }, 1000);
        return () => {
            clearInterval(interval);
        }
    }, [tTimeList, convertTimeRemaining, isLoaded]);

    const LoadCalypsoMissions = <DailyTokenList>
        <div style={{ borderTop: '2px solid', borderImage: 'linear-gradient(to right, rgba(0,0,0,0) 0%, #0dcaf0 20%, #0dcaf0 80%, rgba(0,0,0,0) 100%) 1', marginBottom: '15px', paddingTop: '0.5rem', fontSize: '0.8em' }}>
            <span>Check these quests once you accept them.</span>
        </div>
        <DailyTokenRow id="hunt1">
            <DailyTokenRowHeader isOpen={showDailyContent === 1}>
                <MissionBar className='missionbar'>
                    <MissionBarInfo>
                        <span className="dailyName" onClick={() => {
                            setShowDailyContent(showDailyContent === 1 ? 0 : 1);
                            window.location.href = "#";
                            window.location.href = "#hunt1";
                        }}>
                            Hunting 1 (1)
                        </span>
                        <div style={{ display: 'flex' }}>
                            <span className="dailyTimer">{timePrefix + t1Text}</span>
                            <Checkbox id="dailyHunt1" type="checkbox" checked={t1 > 0} onChange={(e) => {
                                setCountdown('t1', 16, e, setT1);
                            }} />
                        </div>
                    </MissionBarInfo>
                    <MissionBarProgress percentToFinish={t1Time} />
                </MissionBar>
            </DailyTokenRowHeader>
            <DailyTokenRowContent show={showDailyContent === 1}>
                {showDailyContent === 1 && DailyHuntTable(1)}
            </DailyTokenRowContent>
        </DailyTokenRow>
        <DailyTokenRow id="hunt2">
            <DailyTokenRowHeader isOpen={showDailyContent === 2}>
                <MissionBar className='missionbar'>
                    <MissionBarInfo>
                        <span className="dailyName" onClick={() => {
                            setShowDailyContent(showDailyContent === 2 ? 0 : 2);
                            window.location.href = "#";
                            window.location.href = "#hunt2";
                        }}>
                            Hunting 2 (2)
                        </span>
                        <div style={{ display: 'flex' }}>
                            <span className="dailyTimer">{timePrefix + t2Text}</span>
                            <Checkbox id="dailyHunt2" type="checkbox" checked={t2 > 0} onChange={(e) => {
                                setCountdown('t2', 16, e, setT2);
                            }} />
                        </div>
                    </MissionBarInfo>
                    <MissionBarProgress percentToFinish={t2Time} />
                </MissionBar>
            </DailyTokenRowHeader>
            <DailyTokenRowContent show={showDailyContent === 2}>
                {showDailyContent === 2 && DailyHuntTable(2)}
            </DailyTokenRowContent>
        </DailyTokenRow>
        <DailyTokenRow id="hunt3">
            <DailyTokenRowHeader isOpen={showDailyContent === 3}>
                <MissionBar className='missionbar'>
                    <MissionBarInfo>
                        <span className="dailyName" onClick={() => {
                            setShowDailyContent(showDailyContent === 3 ? 0 : 3);
                            window.location.href = "#";
                            window.location.href = "#hunt3";
                        }}>
                            Hunting 3 (3)
                        </span>
                        <div style={{ display: 'flex' }}>
                            <span className="dailyTimer">{timePrefix + t3Text}</span>
                            <Checkbox id="dailyHunt3" type="checkbox" checked={t3 > 0} onChange={(e) => setCountdown('t3', 16, e, setT3)} />
                        </div>
                    </MissionBarInfo>
                    <MissionBarProgress percentToFinish={t3Time} />
                </MissionBar>
            </DailyTokenRowHeader>
            <DailyTokenRowContent show={showDailyContent === 3}>
                {showDailyContent === 3 && DailyHuntTable(3)}
            </DailyTokenRowContent>
        </DailyTokenRow>
        <DailyTokenRow id="craft">
            <DailyTokenRowHeader isOpen={showDailyContent === 4}>
                <MissionBar className='missionbar'>
                    <MissionBarInfo>
                        <span className="dailyName" onClick={() => {
                            setShowDailyContent(showDailyContent === 4 ? 0 : 4);
                            window.location.href = "#";
                            window.location.href = "#craft";
                        }}>
                            Crafting (2)
                        </span>
                        <div style={{ display: 'flex' }}>
                            <span className="dailyTimer">{timePrefix + t4Text}</span>
                            <Checkbox id="dailyCraft" type="checkbox" checked={t4 > 0} onChange={(e) => setCountdown('t4', 16, e, setT4)} />
                        </div>
                    </MissionBarInfo>
                    <MissionBarProgress percentToFinish={t4Time} />
                </MissionBar>
            </DailyTokenRowHeader>
            <DailyTokenRowContent show={showDailyContent === 4}>
                {showDailyContent === 4 && DailyCraftTable()}
            </DailyTokenRowContent>
        </DailyTokenRow>
        <DailyTokenRow id="mining">
            <DailyTokenRowHeader isOpen={showDailyContent === 5}>
                <MissionBar className='missionbar'>
                    <MissionBarInfo>
                        <span className="dailyName" onClick={() => {
                            setShowDailyContent(showDailyContent === 5 ? 0 : 5);
                            window.location.href = "#";
                            window.location.href = "#mining";
                        }}>
                            Mining (1-2)
                        </span>
                        <div style={{ display: 'flex' }}>
                            <span className="dailyTimer">{timePrefix + t5Text}</span>
                            <Checkbox id="dailyMining" type="checkbox" checked={t5 > 0} onChange={(e) => setCountdown('t5', 16, e, setT5)} />
                        </div>
                    </MissionBarInfo>
                    <MissionBarProgress percentToFinish={t5Time} />
                </MissionBar>
            </DailyTokenRowHeader>
            <DailyTokenRowContent show={showDailyContent === 5}>
                {showDailyContent === 5 && DailyMiningTable()}
            </DailyTokenRowContent>
        </DailyTokenRow>
        <div style={{ borderTop: '2px solid', borderImage: 'linear-gradient(to right, rgba(0,0,0,0) 0%, #0dcaf0 20%, #0dcaf0 80%, rgba(0,0,0,0) 100%) 1', marginBottom: '15px', paddingTop: '0.5rem', fontSize: '0.8em' }}>
            <span>Check these quests once you finish and turn them in.</span>
        </div>
        <DailyTokenRow id="feff">
            <DailyTokenRowHeader isOpen={showDailyContent === 6}>
                <MissionBar className='missionbar'>
                    <MissionBarInfo>
                        <span className="dailyName" onClick={() => {
                            setShowDailyContent(showDailyContent === 6 ? 0 : 6);
                            window.location.href = "#feff";
                        }}>
                            Feffoid Cave (1)
                        </span>
                        <div style={{ display: 'flex' }}>
                            <span className="dailyTimer">{timePrefix + t6Text}</span>
                            <Checkbox id="dailyFeffoidCave" type="checkbox" checked={t6 > 0} onChange={(e) => setCountdown('t6', 20, e, setT6)} />
                        </div>
                    </MissionBarInfo>
                    <MissionBarProgress percentToFinish={t6Time} />
                </MissionBar>
            </DailyTokenRowHeader>
            <DailyTokenRowContent show={showDailyContent === 6}>
                {showDailyContent === 6 && <DailyTextQuest>{generateDailyTextQuest('feffoid')}</DailyTextQuest>}
            </DailyTokenRowContent>
        </DailyTokenRow>
        <DailyTokenRow id="argo">
            <DailyTokenRowHeader isOpen={showDailyContent === 7}>
                <MissionBar className='missionbar'>
                    <MissionBarInfo>
                        <span className="dailyName" onClick={() => {
                            setShowDailyContent(showDailyContent === 7 ? 0 : 7);
                            window.location.href = "#argo";
                        }}>
                            Argo Cave (1)
                        </span>
                        <div style={{ display: 'flex' }}>
                            <span className="dailyTimer">{timePrefix + t7Text}</span>
                            <Checkbox id="dailyArgoCave" type="checkbox" checked={t7 > 0} onChange={(e) => setCountdown('t7', 16, e, setT7)} />
                        </div>
                    </MissionBarInfo>
                    <MissionBarProgress percentToFinish={t7Time} />
                </MissionBar>
            </DailyTokenRowHeader>
            <DailyTokenRowContent show={showDailyContent === 7}>
                {showDailyContent === 7 && <DailyTextQuest>{generateDailyTextQuest('argo')}</DailyTextQuest>}
            </DailyTokenRowContent>
        </DailyTokenRow>
        <DailyTokenRow id="jinn">
            <DailyTokenRowHeader isOpen={showDailyContent === 8}>
                <MissionBar className='missionbar'>
                    <MissionBarInfo>
                        <span className="dailyName" onClick={() => {
                            setShowDailyContent(showDailyContent === 8 ? 0 : 8);
                            window.location.href = "#jinn";
                        }}>
                            Jinn's Minions (4)
                        </span>
                        <div style={{ display: 'flex' }}>
                            <span className="dailyTimer">{timePrefix + t8Text}</span>
                            <Checkbox id="dailyJinn" type="checkbox" checked={t8 > 0} onChange={(e) => setCountdown('t8', 16, e, setT8)} />
                        </div>
                    </MissionBarInfo>
                    <MissionBarProgress percentToFinish={t8Time} />
                </MissionBar>
            </DailyTokenRowHeader>
            <DailyTokenRowContent show={showDailyContent === 8}>
                {showDailyContent === 8 && <DailyTextQuest>{generateDailyTextQuest('jinn')}</DailyTextQuest>}
            </DailyTokenRowContent>
        </DailyTokenRow>
        <DailyTokenRow id="bloodclaw">
            <DailyTokenRowHeader isOpen={showDailyContent === 9}>
                <MissionBar className='missionbar'>
                    <MissionBarInfo>
                        <span className="dailyName" onClick={() => {
                            setShowDailyContent(showDailyContent === 9 ? 0 : 9);
                            window.location.href = "#bloodclaw";
                        }}>
                            Bloodclaw (6)
                        </span>
                        <div style={{ display: 'flex' }}>
                            <span className="dailyTimer">{timePrefix + t9Text}</span>
                            <Checkbox id="dailyBloodclaw" type="checkbox" checked={t9 > 0} onChange={(e) => setCountdown('t9', 23, e, setT9)} />
                        </div>
                    </MissionBarInfo>
                    <MissionBarProgress percentToFinish={t9Time} />
                </MissionBar>
            </DailyTokenRowHeader>
            <DailyTokenRowContent show={showDailyContent === 9}>
                {showDailyContent === 9 && <DailyTextQuest>{generateDailyTextQuest('bloodclaw')}</DailyTextQuest>}
            </DailyTokenRowContent>
        </DailyTokenRow>
    </DailyTokenList>;

    return <Main>
        {/* <div>
            <PlanetList>
                <div onClick={() => setSelectedPlanet(DailyMissionPlanet.Calypso)}>
                    Calypso
                </div>
                <div onClick={() => setSelectedPlanet(DailyMissionPlanet.Cyrene)}>
                    Cyrene
                </div>
            </PlanetList>
        </div> */}
        <DailyTokenContainer>
            {selectedPlanet === DailyMissionPlanet.Calypso && <DailyTokenTitle>Daily Calypso Token Missions</DailyTokenTitle>}
            {selectedPlanet === DailyMissionPlanet.Cyrene && <DailyTokenTitle>Daily Cyrene Missions</DailyTokenTitle>}
            <p style={{ fontSize: '0.7em', marginBottom: '25px' }}>
                Click on a mission to open details
            </p>
            {!isLoaded
                ? <Loading />
                : LoadCalypsoMissions
            }
            <div style={{ fontSize: '0.7em', marginBottom: '15px', paddingBottom: '20px' }}>*Hunting costs are based on a 3dpp weapon hunting lowest maturity and a 90% hit rate.</div>
        </DailyTokenContainer>
    </Main>
}