import { useState } from 'react';

type ILocalStorageValue = boolean | number | object | string | null;
type UseLocalStorage = [any, (value: any) => void];

interface ILocalStorageHook {
  (key: string, initialValue: ILocalStorageValue): UseLocalStorage;
}

export const useLocalStorage: ILocalStorageHook = (key, initialValue) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);

      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      return initialValue;
    }
  });

  const setValue = (value: any) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;

      setStoredValue(valueToStore);

      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.log(error);
    }
  };

  return [storedValue, setValue];
};
