import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Dropdown } from '../../inputs/Dropdown';
import { Teleports } from '../../../models/Teleports';
import copyIcon from '../TeleportFinder/images/copy-icon.png'

const Main = styled.div({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 'auto',
    margin: '0 auto',
    color: 'white',
    backgroundColor: 'rgba(20,20,20,1)',
    paddingTop: '1em',
    paddingBottom: '1em'
});

const CopyTooltip = styled.span({
    position: 'absolute',
    visibility: 'hidden',
    left: '-20px',
    top: '-40px',
    width: 'fit-content',
    backgroundColor: '#444',
    borderRadius: '5px',
    color: 'white',
    padding: '0.5rem',
});

const CopyToClipboardButton = styled.button({
    position: 'relative',
    backgroundImage: `url(${copyIcon})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    width: '20px',
    height: '20px',
    backgroundColor: 'transparent',
    border: 'none',
    marginLeft: '0.5em',
    marginTop: '-5px',
    verticalAlign: 'middle',

    '&:hover': {
        cursor: 'pointer',

        '.tooltip': {
            visibility: 'visible',
        }
    }
});

const generateTeleportList = () => {
    let teleportNames: string[] = [];

    Teleports.Eudoria.forEach(x => teleportNames.push(x.Name));
    Teleports.Amethera.forEach(x => teleportNames.push(x.Name));
    Teleports.Odysseus.forEach(x => teleportNames.push(x.Name));
    Teleports.Ouroboros.forEach(x => teleportNames.push(x.Name));

    let sortedNames = teleportNames.sort((a, b) => a.localeCompare(b));
    let names = sortedNames.map(i => <option key={i} value={i}>{i}</option>);

    return new Set(names);
}

export const TeleportFinder: React.FC = () => {
    const [waypoint, setWaypoint] = useState('');
    const [Longitude, setLongitude] = useState(0);
    const [Latitude, setLatitude] = useState(0);
    const [Height, setHeight] = useState(0);
    const [TeleportName, setTeleportName] = useState('');
    const [TooltipText, setTooltipText] = useState('Copy');
    const TeleportList = Teleports.Eudoria
        .concat(Teleports.Amethera
            .concat(Teleports.Odysseus
                .concat(Teleports.Ouroboros)));

    useEffect(() => {
        setWaypoint(`/wp [Calypso, ${Longitude}, ${Latitude}, ${Height}, ${TeleportName}]`);
    }, [Longitude, Latitude, TeleportName, Height]);

    return <Main>
        <h2 style={{ marginBottom: 0, marginTop: 0 }}>Calypso Teleport Finder</h2>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {/* <h3 style={{ marginBottom: 0 }}>TELEPORT LOCATIONS</h3> */}
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', paddingBottom: 0 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', position: 'relative' }}>
                        <Dropdown placeholder='Select Location' onChange={e => {
                            var teleportData = TeleportList.find(x => x.Name === e.currentTarget.value);
                            if (teleportData) {
                                setLongitude(teleportData.Longitude);
                                setLatitude(teleportData.Latitude);
                                setTeleportName(teleportData.Name);
                                setHeight(teleportData.Z);
                            }
                        }}>
                            {generateTeleportList()}
                        </Dropdown>
                        {TeleportName.length > 0 && <button style={{ position: 'absolute', right: -15, padding: 0 }} onClick={() => {
                            setTeleportName("");
                            setWaypoint("");
                            setLongitude(0);
                            setLatitude(0);
                            setHeight(0);
                        }}>
                            <h2 style={{ paddingTop: '6px' }}>X</h2>
                        </button>}
                    </div>
                </div>
            </div>
        </div>
        {TeleportName.length > 0 && (<span style={{ backgroundColor: 'rgba(20,20,20,1)', appearance: 'auto' }} onClick={() => navigator.clipboard.writeText(waypoint)}>
            <p style={{ marginTop: 0, paddingTop: 0, fontSize: 'small' }}>Copy & Paste the following into game chat:</p>
            <span id="waypointLink">{waypoint}</span>
            <CopyToClipboardButton onClick={() => {
                setTooltipText('Copied');
                setTimeout(() => {
                    setTooltipText('Copy to clipboard');
                }, 2000);
            }}>
                <CopyTooltip className="tooltip">{TooltipText}</CopyTooltip>
            </CopyToClipboardButton>
        </span>)}
    </Main>
}