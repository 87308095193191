import React from 'react';

export interface landAreaDetails {
  Name: string,
  id: string,
  notes: string,
  huntingTax: number,
  miningTax: number,
  shoppingTax: number,
  owner: string,
  feederPos: string,
  mobsList: [landAreaDetailsMobList],
  resourceList: landAreaDetailsResourceList,
  border: string,
  addOffsetXY: boolean
}

export interface landAreaDetailsMobList {
  name: string,
  maturity: landAreaDetailsMobMaturity,
  DensitySize: number
}

export interface landAreaDetailsMobMaturity {
  min: string,
  max: string
}

export interface landAreaDetailsResourceList {
  enmatter: [string],
  ores: [string]
}

export const generateLandAreaList = () => {
  let areaNames: string[] = [];

  landAreasList.forEach(x => areaNames.push(x.Name));

  let sortedNames = areaNames.sort((a, b) => a.localeCompare(b));

  let names = sortedNames.map(i => <option key={i} value={i}>{i}</option>);

  if (names.length > 0) {
    // names.unshift(<option key='All' value={'all'}>All</option>);
    names.unshift(<option key='None' value={''}>None</option>);
  }

  return new Set(names);
}

export const landAreasList = [
  { // Amethera Outback Land #21
    Name: 'Amethera Outback Land #21',
    id: '21',
    notes: '',
    huntingTax: 3.99,
    miningTax: 3.0,
    shoppingTax: 2.50,
    owner: 'Toni Chiee Lahderinne',
    feederPos: '38955,47746,150',
    mobsList: [
      { name: 'Formicacida', maturity: { min: 'Weak', max: 'Strong' }, DensitySize: 95 }
    ],
    resourceList: {
      enmatter: [''],
      ores: ['']
    },
    border: '38245,48650 39205,49018 39397,48458 39381,48266 39589,47802 40005,47338 39221,46314 38773,47006 38293,47162 38133,47610',
  },
  { // Amethera LA1
    Name: 'Amethera LA1',
    id: 'LA1',
    notes: '',
    huntingTax: 0.0,
    miningTax: 0.0,
    shoppingTax: 2.50,
    owner: 'Marcus Callender The Estate Broker',
    feederPos: '37891,46527,148',
    mobsList: [
      { name: 'Thorifoid Shaman', maturity: { min: 'Inept', max: '' }, DensitySize: 25 },
      { name: 'Bristlehog', maturity: { min: 'Young', max: '' }, DensitySize: 10 },
      { name: 'Trilomite', maturity: { min: 'Young', max: 'Old' }, DensitySize: 10 }
    ],
    resourceList: {
      enmatter: [''],
      ores: ['']
    },
    border: '36936,47024 37432,47152 37640,46720 38200,46720 38270,46208 38200,45616 37480,45312 37208,45392 37096,45648 36792,45712 36792,46592',
  },
  { // Amethera Outback Land #22
    Name: 'Amethera Outback Land #22',
    id: '22',
    notes: '',
    huntingTax: 3.90,
    miningTax: 3.90,
    shoppingTax: 2.50,
    owner: 'Mike Dragonfly Colder',
    feederPos: '37691,48310,182',
    mobsList: [
      { name: 'Scaboreas', maturity: { min: 'Young', max: 'Old' }, DensitySize: 50 },
      { name: 'Exarosaur', maturity: { min: 'Young', max: 'Dominant' }, DensitySize: 25 },
    ],
    resourceList: {
      enmatter: [''],
      ores: ['']
    },
    border: '36376,48672 37080,48848 37848,48432 38008,48000 37352,47328 36760,47600 36424,48160',
  },
  { // Amethera Outback Land #43
    Name: 'Amethera Outback Land #43',
    id: '43',
    notes: '',
    huntingTax: 3.99,
    miningTax: 3.99,
    shoppingTax: 2.50,
    owner: 'McKenna Kenney Freeman',
    feederPos: '18885,64753,122',
    mobsList: [
      { name: 'Mourner', maturity: { min: 'Young', max: '' }, DensitySize: 10 }
    ],
    resourceList: {
      enmatter: ['Azur', 'Ferrum', 'Typonolic', 'Lytairian', 'Garcen'],
      ores: ['Dianum', 'Narcanisum', 'Zinc', 'Cumbriz', 'Platinum', 'Langotz', 'Gazzurdite', 'Ignisium']
    },
    border: '18251,65241 19227,65337 20507,64969 20843,63785 19243,63673 17995,63721 17803,63785 17739,64057 17915,64873',
    addOffsetXY: true
  },
  { // Amethera Outback Land #46
    Name: 'Amethera Outback Land #46',
    id: '46',
    notes: '',
    huntingTax: 3.99,
    miningTax: 3.50,
    shoppingTax: 2.50,
    owner: 'Toni Chiee Lahderinne',
    feederPos: '19016,62287,138',
    mobsList: [
      { name: 'Proteron', maturity: { min: 'Young', max: '' }, DensitySize: 90 }
    ],
    resourceList: {
      enmatter: ['Garcen', 'Magerian', 'Root Acid', 'Melchi', 'Azur', 'Solis', 'Devil\'s', 'Binary', 'Lumis', 'Lytairian', 'Typonolic'],
      ores: ['Niksarium', 'Durulium', 'Vesperdite', 'Valurite', 'Megan', 'Azzurdite', 'Erdorium', 'Narcanisum', 'Gazzurdite', 'Cumbriz', 'Zinc', 'Lysterium', 'Belkar']
    },
    border: '17803,63417 18011,63241 19371,63241 19723,62777 19643,62633 19931,62249 19531,61833 18987,61545 17819,61449 17387,61289 17259,61273 17163,61497 17323,61977 17211,62185 17435,62265 17291,62697 17707,63209',
    addOffsetXY: true
  },
  { // Amethera Outback Land #47
    Name: 'Amethera Outback Land #47',
    id: '47',
    notes: '',
    huntingTax: 3.99,
    miningTax: 3.99,
    shoppingTax: 2.50,
    owner: 'Buck Buck Stone',
    feederPos: '21862,62350,129',
    mobsList: [
      { name: 'Chomper', maturity: { min: 'Young', max: '' }, DensitySize: 5 }
    ],
    resourceList: {
      enmatter: ['Cave Sap', 'Angelic', 'Alicenies', 'Ares', 'Oil', 'Energized Crystal', 'Force', 'Binary'],
      ores: ['Ignisium', 'Vesperdite', 'Adomasite', 'Erdorium', 'Terrudite', 'Blausariam', 'Belkar', 'Caldorite', 'Copper', 'Lysterium']
    },
    border: '22091,64169 22235,64169 22891,63689 23067,63049 23067,62921 22971,62777 23163,62617 23035,62489 22715,62441 22603,62233 22379,62313 22203,62265 22139,61993 21755,61993 21435,62377 21195,62553 21195,63065 21291,63481 21435,63689 21755,63785',
    addOffsetXY: true
  },
  { // Amethera Outback Land #45
    Name: 'Amethera Outback Land #45',
    id: '45',
    notes: '',
    huntingTax: 3.99,
    miningTax: 5.00,
    shoppingTax: 2.50,
    owner: 'Sarah Witchblade0815 Pezzinni',
    feederPos: '18066,59344,125',
    mobsList: [
      { name: 'Hogglo', maturity: { min: 'Young', max: 'Mature' }, DensitySize: 90 }
    ],
    resourceList: {
      enmatter: [''],
      ores: ['']
    },
    border: '17163,60505 17435,60297 17995,60281 19355,59289 19099,58665 18059,57705 17419,57705 17243,57769 16955,58009 16795,58249 16667,58601 16699,59481 16891,60345 16987,60473',
    addOffsetXY: true
  },
  { // Amethera Outback Land #44
    Name: 'Amethera Outback Land #44',
    id: '45',
    notes: '',
    huntingTax: 3.99,
    miningTax: 3.99,
    shoppingTax: 2.50,
    owner: 'Caimen Caimen Rochford',
    feederPos: '19988,60316,112',
    mobsList: [
      { name: 'Exarosaur', maturity: { min: 'Stalker', max: '' }, DensitySize: 80 }
    ],
    resourceList: {
      enmatter: ['Devil\'s', 'Garcen', 'Magerian', 'Acid Root', 'Oil', 'Melchi', 'Force', 'Growth'],
      ores: ['Megan', 'Niksarium', 'Durulium', 'Azzurdite', 'Lysterium']
    },
    border: '19915,61337 20971,61193 20843,60473 20523,59705 20123,59545 19243,59721 19131,60569 19307,61129',
    addOffsetXY: true
  },
  { // Amethera Outback Land #36
    Name: 'Amethera Outback Land #36',
    id: '36',
    notes: '',
    huntingTax: 0.0,
    miningTax: 0.0,
    shoppingTax: 2.50,
    owner: 'MindArk Official Annex',
    feederPos: '22671,55641,176',
    mobsList: [
      { name: 'Shinkiba', maturity: { min: 'Young', max: '' }, DensitySize: 10 },
      { name: 'Berycled', maturity: { min: 'Young', max: '' }, DensitySize: 20 },
      { name: 'Hispidus', maturity: { min: 'Young', max: '' }, DensitySize: 5 }
    ],
    resourceList: {
      enmatter: [''],
      ores: ['']
    },
    border: '21915,56223 22779,56223 23019,55593 23019,55049 22779,54569 22619,54521 22635,54233 22363,54105 21963,54153 22091,54553 21931,54697 21771,55273',
    addOffsetXY: true
  },
  { // Amethera Outback Land #35
    Name: 'Amethera Outback Land #35',
    id: '35',
    notes: '',
    huntingTax: 0.0,
    miningTax: 0.0,
    shoppingTax: 2.50,
    owner: 'MindArk Official Annex',
    feederPos: '19980,54015,272',
    mobsList: [
      { name: 'Combibo', maturity: { min: 'Young', max: '' }, DensitySize: 15 },
      { name: 'Cornundos', maturity: { min: 'Young', max: 'Old' }, DensitySize: 15 },
      { name: 'Repesilex', maturity: { min: 'Young', max: '' }, DensitySize: 20 }
    ],
    resourceList: {
      enmatter: [''],
      ores: ['']
    },
    border: '19259,55545 20907,55577 21307,54089 21179,53721 19595,53721 19035,54137 18891,54297 18891,54841 19243,55161',
    addOffsetXY: true
  },
  { // Eldorado
    Name: 'Eldorado',
    id: 'Eldorado',
    notes: '',
    huntingTax: 2.99,
    miningTax: 2.99,
    shoppingTax: 2.50,
    owner: 'George Geo Clone',
    feederPos: '17764,51733,151',
    mobsList: [
      { name: 'Equus', maturity: { min: 'Young', max: 'Mature' }, DensitySize: 39 },
      { name: 'Plumatergus', maturity: { min: 'Young', max: 'Old' }, DensitySize: 35 }
    ],
    resourceList: {
      enmatter: [''],
      ores: ['']
    },
    border: '17627,54105 18379,54297 19499,52857 19515,52361 18619,52105 18619,51257 18347,50841 17083,51321 16971,51481 16827,51849 17371,51961',
    addOffsetXY: true
  },
  { // That Freaking Cold Place
    Name: 'That Freaking Cold Place',
    id: 'ColdPlace',
    notes: '',
    huntingTax: 4.0,
    miningTax: 4.0,
    shoppingTax: 2.50,
    owner: 'Rachel MsPudding Hawkins',
    feederPos: '18796,51538,152',
    mobsList: [
      { name: 'Neconu', maturity: { min: 'Young', max: 'Mature' }, DensitySize: 85 }
    ],
    resourceList: {
      enmatter: ['Angelic', 'Force', 'Devil\'s', 'Acid', 'Henren'],
      ores: ['Belkar', 'Lanorium', 'Narcanisum', 'Gold', 'Langotz', 'Zanderium']
    },
    border: '18763,51961 19723,52249 21003,52249 21547,51897 21547,50889 21467,50361 20747,49977 19835,49945 19595,50393 19307,50585 18971,50585 18763,51033',
    addOffsetXY: true
  },
  { // Amethera Outback Land #48
    Name: 'Amethera Outback Land #48',
    id: '48',
    notes: '',
    huntingTax: 3.99,
    miningTax: 3.99,
    shoppingTax: 2.50,
    owner: 'Blastoise Meculus Yarlboro',
    feederPos: '17984,46651,115',
    mobsList: [
      { name: 'Nexnecis', maturity: { min: 'Stalker', max: '' }, DensitySize: 90 },
      { name: 'Allophyl', maturity: { min: 'Young', max: '' }, DensitySize: 10 },
    ],
    resourceList: {
      enmatter: [''],
      ores: ['']
    },
    border: '16939,48953 17707,49017 17931,48905 18139,47161 18091,46409 16587,46409 16459,47929 16507,48185',
    addOffsetXY: true
  },
  { // Amethera Outback Land #49
    Name: 'Amethera Outback Land #49',
    id: '49',
    notes: '',
    huntingTax: 3.99,
    miningTax: 3.99,
    shoppingTax: 2.50,
    owner: 'Blastoise Meculus Yarlboro',
    feederPos: '19305,46593,262',
    mobsList: [
      { name: 'Rextelum', maturity: { min: 'Watcher', max: 'Ravager' }, DensitySize: 90 }
    ],
    resourceList: {
      enmatter: [''],
      ores: ['']
    },
    border: '18155,47977 19867,48377 19915,46729 19595,46409 18299,46505',
    addOffsetXY: true
  },
  { // Amethera Outback Land #51
    Name: 'Amethera Outback Land #51',
    id: '51',
    notes: '',
    huntingTax: 3.99,
    miningTax: 2.99,
    shoppingTax: 2.50,
    owner: 'Gemini Nana Prudential',
    feederPos: '21391,46948,118',
    mobsList: [
      { name: 'Osseocollum', maturity: { min: 'Young', max: 'Old' }, DensitySize: 90 }
    ],
    resourceList: {
      enmatter: ['Devil\'s', 'Henren', 'Oil'],
      ores: ['Zanderium', 'Adomasite', 'Dianum', 'Blausariam', 'Frigulite', 'Lysterium']
    },
    border: '20507,48745 21003,48489 21531,48489 21499,47593 21931,47145 21931,46713 20027,46665 20027,48153',
    addOffsetXY: true
  },
  { // Amethera Outback Land #52
    Name: 'Amethera Outback Land #52',
    id: '52',
    notes: '',
    huntingTax: 3.50,
    miningTax: 3.50,
    shoppingTax: 2.50,
    owner: 'lecomte ERACLES mickael',
    feederPos: '22133,46847,155',
    mobsList: [
      { name: 'Molisk', maturity: { min: 'Young', max: '' }, DensitySize: 50 }
    ],
    resourceList: {
      enmatter: [''],
      ores: ['']
    },
    border: '21659,48553 23083,48633 23643,47561 23739,47097 23499,46777 22075,46777 22059,47145 21627,47625',
    addOffsetXY: true
  },
  { // Amethera Outback Land #53
    Name: 'Amethera Outback Land #53',
    id: '53',
    notes: '',
    huntingTax: 3.99,
    miningTax: 3.50,
    shoppingTax: 2.50,
    owner: 'Toni Chiee Lahderinne',
    feederPos: '18466,45730,118',
    mobsList: [
      { name: 'Exarosaur', maturity: { min: 'Young', max: '' }, DensitySize: 100 },
      { name: 'Fugabarba', maturity: { min: 'Young', max: '' }, DensitySize: 5 },
      { name: 'Snarksnot', maturity: { min: 'Young', max: '' }, DensitySize: 25 },
      { name: 'Hiryuu', maturity: { min: 'Young', max: '' }, DensitySize: 10 },
      { name: 'Snablesnot Male', maturity: { min: 'Young', max: '' }, DensitySize: 5 },
      { name: 'Snablesnot Female', maturity: { min: 'Young', max: '' }, DensitySize: 5 }
    ],
    resourceList: {
      enmatter: [''],
      ores: ['']
    },
    border: '16587,46281 19803,46169 19947,45561 19899,44777 18987,44633 18219,44681 18187,44809 17435,44985 16891,44601 16635,44873 16571,44985 16699,45641',
    addOffsetXY: true
  },
  { // Amethera Outback Land #54
    Name: 'Amethera Outback Land #54',
    id: '54',
    notes: '',
    huntingTax: 5.99,
    miningTax: 3.50,
    shoppingTax: 2.50,
    owner: 'Toni Chiee Lahderinne',
    feederPos: '21528,46294,116',
    mobsList: [
      { name: 'Proteron', maturity: { min: 'Old', max: 'Dominant' }, DensitySize: 85 },
      { name: 'Chomper (West)', maturity: { min: 'Old', max: 'Dominant' }, DensitySize: 25 },
    ],
    resourceList: {
      enmatter: ['Dianthus', 'Acid', 'Angelic', 'Devil\'s', 'Henren', 'Force', 'Oil'],
      ores: ['Adomasite', 'Belkar', 'Blausariam', 'Cumbriz', 'Dianum', 'Frigulite', 'Lanorium', 'Lysterium', 'Narcanisum']
    },
    border: '20075,46153 20299,46313 20843,46473 22427,46473 22731,45593 22203,45369 22059,45161 21659,45097 21563,45113 21371,44953 21339,45033 21339,45161 21147,45209 20939,45289 20795,45209 20683,45209 20475,44985 20219,44905 20075,45001',
    addOffsetXY: true
  },
  { // Amethera Outback Land #34
    Name: 'Amethera Outback Land #34',
    id: '34',
    notes: '',
    huntingTax: 0.0,
    miningTax: 0.0,
    shoppingTax: 2.50,
    owner: 'MindArk Official Annex',
    feederPos: '23776,45484,113',
    mobsList: [
      { name: 'Atrox', maturity: { min: 'Young', max: 'Old' }, DensitySize: 10 },
      { name: 'Hogglo', maturity: { min: 'Young', max: '' }, DensitySize: 10 }
    ],
    resourceList: {
      enmatter: [''],
      ores: ['']
    },
    border: '22923,46665 23691,46633 24203,46713 24491,44617 24363,43977 23579,43769 22875,43881 22939,46009',
    addOffsetXY: true
  },
  { // Amethera Outback Land #28
    Name: 'Amethera Outback Land #28',
    id: '28',
    notes: 'PEDflow.com',
    huntingTax: 3.99,
    miningTax: 4.0,
    shoppingTax: 2.50,
    owner: 'Jess JessBelluhm Jones',
    feederPos: '25946,46822,140',
    mobsList: [
      { name: 'Atrax', maturity: { min: 'Young', max: '' }, DensitySize: 15 }
    ],
    resourceList: {
      enmatter: [''],
      ores: ['']
    },
    border: '25659,47225 26955,47273 26923,47161 26923,46985 26859,46905 26843,46761 26891,46697 26875,46409 26811,46345 26795,46233 26859,46025 26811,45913 26667,45897 26683,45769 26555,45577 26507,45417 26411,45273 26251,45273 25579,46121 25467,46841',
    addOffsetXY: true
  },
  { // Amethera Outback Land #38
    Name: 'Amethera Outback Land #38',
    id: '38',
    notes: '',
    huntingTax: 0.0,
    miningTax: 0.0,
    shoppingTax: 2.50,
    owner: 'MindArk Official Annex',
    feederPos: '18458,42416,111',
    mobsList: [
      { name: 'Cornundacauda', maturity: { min: 'Young', max: '' }, DensitySize: 15 },
      { name: 'Phasm', maturity: { min: 'Young', max: '' }, DensitySize: 15 }
    ],
    resourceList: {
      enmatter: [''],
      ores: ['']
    },
    border: '16731,44137 16923,44217 17211,44153 18347,44265 19179,43865 19387,43065 19307,42137 18843,41625 18059,41801 17755,42073 17499,41977 17355,41977 16875,42361 16715,42809 16763,43161 16683,43337 16683,43481 16779,43657',
    addOffsetXY: true
  },
  { // Amethera Outback Land #55
    Name: 'Amethera Outback Land #55',
    id: '55',
    notes: 'BIG Industries',
    huntingTax: 4.0,
    miningTax: 4.0,
    shoppingTax: 2.50,
    owner: 'BonBon Bonalanious Bonfire',
    feederPos: '20366,43133,225',
    mobsList: [
      { name: 'Itumatrox', maturity: { min: 'Young', max: '' }, DensitySize: 30 },
      { name: 'Atrax', maturity: { min: 'Young', max: '' }, DensitySize: 10 }
    ],
    resourceList: {
      enmatter: [''],
      ores: ['']
    },
    border: '18923,44553 20427,44505 20651,43625 20715,42281 19499,42041 19499,43065 19307,43833',
    addOffsetXY: true
  },
  { // Amethera Outback Land #33
    Name: 'Amethera Outback Land #33',
    id: '33',
    notes: '',
    huntingTax: 0.0,
    miningTax: 0.0,
    shoppingTax: 2.50,
    owner: 'MindArk Official Annex',
    feederPos: '21728,43464,137',
    mobsList: [
      { name: 'Maffoid', maturity: { min: 'Outcast', max: 'Hunter' }, DensitySize: 30 }
    ],
    resourceList: {
      enmatter: [''],
      ores: ['']
    },
    border: '20555,44505 21195,44521 21323,44441 21339,44249 21499,44153 21627,44217 21691,44377 21579,44585 21691,44713 21883,44521 22043,44537 22091,44665 22395,44665 22315,44489 22427,43977 22395,43465 22187,42937 21659,42521 20971,42425 20779,42617 20779,43289',
    addOffsetXY: true
  },
  { // Amethera Outback Land #41
    Name: 'Amethera Outback Land #41 West',
    id: '41West',
    notes: '',
    huntingTax: 4.90,
    miningTax: 3.90,
    shoppingTax: 2.50,
    owner: 'Modified Akoz Power',
    feederPos: '26218,61018,130',
    mobsList: [
      { name: 'Argonaut', maturity: { min: 'Young', max: 'Scout' }, DensitySize: 99 }
    ],
    resourceList: {
      enmatter: [''],
      ores: ['']
    },
    border: '26008,63725 27784,60573 27688,60541 27384,60653 27160,60781 27112,60717 26952,60589 26840,60557 26264,60717 25944,61021 25960,62205 25800,62333 25832,62477 25784,62589 25880,62909 25464,63005 25384,63213 25528,63293 25576,63421 25640,63485 25640,63565 25704,63613 25768,63613',
    addOffsetXY: true
  },
  { // Amethera Outback Land #41
    Name: 'Amethera Outback Land #41 North',
    id: '41North',
    notes: '',
    huntingTax: 4.90,
    miningTax: 3.50,
    shoppingTax: 2.50,
    owner: 'Modified Akoz Power',
    feederPos: '26218,61018,130',
    mobsList: [
      { name: 'Argonaut', maturity: { min: 'Young', max: 'Scout' }, DensitySize: 10 }
    ],
    resourceList: {
      enmatter: [''],
      ores: ['']
    },
    border: '26028,63725 26472,63725 27400,63357 27576,63437 27816,63341 27896,63277 28408,63325 28728,63245 27000,61997',
    addOffsetXY: true
  },
  { // Amethera Outback Land #41
    Name: 'Amethera Outback Land #41 East',
    id: '41East',
    notes: '',
    huntingTax: 1.50,
    miningTax: 2.50,
    shoppingTax: 2.50,
    owner: 'Suza Suze Rider',
    feederPos: '26218,61018,130',
    mobsList: [],
    resourceList: {
      enmatter: ['Acid', 'Alicenies', 'Ares', 'Azur', 'Cave Sap', 'Oil', 'Energized Crystals', 'Force', 'Garcen', 'Lytairian', 'Magerian', 'Melchi', 'Typonolic'],
      ores: ['Adomasite', 'Azzurdite', 'Belkar', 'Blausarsiam', 'Caldorite', 'Copper', 'Cumbriz', 'Gazzurdite', 'Gold', 'Ignisium', 'Kanerium', 'Lysterium', 'Narcanisum', 'Niksarium', 'Petonium', 'Redulite', 'Zinc']
    },
    border: '28728,63245 28904,63021 28936,62877 28808,62749 28888,62573 28824,62509 28840,62349 29352,62269 29608,61725 29768,61677 29768,61581 29688,61549 29704,61261 29608,61149 29640,61005 29560,60957 29592,60861 29496,60813 29416,60685 28728,60781 28584,60733 28408,60701 28280,60733 28104,60669 27896,60717 27752,60653 27020,62000',
    addOffsetXY: true
  },
  { // Amethera Outback Land #42
    Name: 'Amethera Outback Land #42 North',
    id: '42North',
    notes: 'BIG Industries',
    huntingTax: 3.99,
    miningTax: 3.99,
    shoppingTax: 2.50,
    owner: 'Blastoise Meculus Yarlboro',
    feederPos: '29066,58792,120',
    mobsList: [
      { name: 'Ambulimax', maturity: { min: 'Young', max: '' }, DensitySize: 90 }
    ],
    resourceList: {
      enmatter: [''],
      ores: ['']
    },
    border: '28872,59885 29784,59885 30312,59517 30296,59213 29688,58429 29064,58733 28744,59133 28712,59725',
    addOffsetXY: true
  },
  { // Amethera Outback Land #42
    Name: 'Amethera Outback Land #42 West',
    id: '42West',
    notes: 'BIG Industries',
    huntingTax: 3.99,
    miningTax: 3.99,
    shoppingTax: 2.50,
    owner: 'Blastoise Meculus Yarlboro',
    feederPos: '29066,58792,120',
    mobsList: [
      { name: 'Atrox', maturity: { min: 'Old', max: 'Dominant' }, DensitySize: 90 }
    ],
    resourceList: {
      enmatter: [''],
      ores: ['']
    },
    border: '28056,59533 28360,59485 28440,59421 28392,59181 28760,59117 29064,58733 29064,57437 27768,57437 27640,58381 28120,58413 28216,58909',
    addOffsetXY: true
  },
  { // Amethera Outback Land #42
    Name: 'Amethera Outback Land #42 East',
    id: '42East',
    notes: 'BIG Industries',
    huntingTax: 3.99,
    miningTax: 3.99,
    shoppingTax: 2.50,
    owner: 'Blastoise Meculus Yarlboro',
    feederPos: '29066,58792,120',
    mobsList: [
      { name: 'Argonaut', maturity: { min: 'Young', max: '' }, DensitySize: 50 },
      { name: 'Furor', maturity: { min: 'Adept', max: '' }, DensitySize: 80 },
      { name: 'Fungoid', maturity: { min: 'Young', max: '' }, DensitySize: 80 }
    ],
    resourceList: {
      enmatter: [''],
      ores: ['']
    },
    border: '29064,58733 29720,58429 29768,58253 30120,58061 30392,58605 31096,58253 30664,57437 29064,57437',
    addOffsetXY: true
  },
  { // Amethera Outback Land #56
    Name: 'Amethera Outback Land #56',
    id: '56',
    notes: '',
    huntingTax: 3.99,
    miningTax: 3.99,
    shoppingTax: 2.50,
    owner: 'Aleph Levi Benzait',
    feederPos: '26727,56276,195',
    mobsList: [
      { name: 'Berycled', maturity: { min: 'Young', max: '' }, DensitySize: 40 }
    ],
    resourceList: {
      enmatter: ['Oil', 'Cave Sap', 'Typonolic', 'Magerian', 'Garcen'],
      ores: ['Belkar', 'Durulium', 'Kanerium', 'Gazzurdite', 'Redulite', 'Rugaritz', 'Lysterium']
    },
    border: '24872,57165 26280,57245 26920,57197 27400,57085 27496,56733 27352,56301 27080,55997 26792,55773 26344,55725 25624,55789 25544,55997 25384,56093 25400,56317 25224,56509 25048,56653 25000,56941',
    addOffsetXY: true
  },
  { // Amethera Outback Land #57
    Name: 'Amethera Outback Land #57',
    id: '57',
    notes: '',
    huntingTax: 4.90,
    miningTax: 4.90,
    shoppingTax: 2.50,
    owner: 'Lee Zhorgul Salmon',
    feederPos: '29256,562678,184',
    mobsList: [
      { name: 'Armax Bull', maturity: { min: 'Young', max: '' }, DensitySize: 10 },
      { name: 'Armax Cow', maturity: { min: 'Young', max: '' }, DensitySize: 10 }
    ],
    resourceList: {
      enmatter: ['Oil'],
      ores: ['Rugaritz', 'Redulite', 'Lysterium']
    },
    border: '27992,57245 28552,57197 29656,57261 30488,57213 30536,56781 30744,56557 30552,56125 30184,56029 29720,55981 29416,56093 28952,56269 28344,56205 27816,56125 27656,56605 27704,56957',
    addOffsetXY: true
  },
  { // Amethera Outback Land #32
    Name: 'Amethera Outback Land #32',
    id: '32',
    notes: '',
    huntingTax: 4.90,
    miningTax: 4.90,
    shoppingTax: 2.50,
    owner: 'Lee Zhorgul Salmon',
    feederPos: '29163,55987,219',
    mobsList: [
      { name: 'Atrax', maturity: { min: 'Young', max: 'Mature' }, DensitySize: 90 }
    ],
    resourceList: {
      enmatter: [''],
      ores: ['']
    },
    border: '27416,56173 28008,56029 28472,55997 28920,56141 29432,56013 29928,55341 29880,55197 28360,55213 27912,55117 27624,54877 27240,54861 27016,54989 26808,55373 26920,55805',
    addOffsetXY: true
  },
  { // Amethera Outback Land #58
    Name: 'Amethera Outback Land #58',
    id: '58',
    notes: '',
    huntingTax: 0.0,
    miningTax: 0.0,
    shoppingTax: 2.50,
    owner: 'Marcus Callender The Estate Broker',
    feederPos: '31164,56601,123',
    mobsList: [
      { name: 'Foul (North-West)', maturity: { min: 'Scavanger', max: 'Hunter' }, DensitySize: 5 }
    ],
    resourceList: {
      enmatter: [''],
      ores: ['']
    },
    border: '30664,57213 31864,57245 32600,57149 32664,55869 32568,54973 31784,55213 31272,55117 30776,55101 30344,55165 30088,55245 29960,55373 29944,55549 30456,55789 30696,56205 30968,56589 30936,56829 30696,56941',
    addOffsetXY: true
  },
  { // Amethera Outback Land #31
    Name: 'Amethera Outback Land #31',
    id: '31',
    notes: '',
    huntingTax: 2.0,
    miningTax: 2.0,
    shoppingTax: 2.50,
    owner: 'Coover coover Tristan',
    feederPos: '30913,54324,149',
    mobsList: [
      { name: 'Berycled', maturity: { min: 'Young', max: '' }, DensitySize: 10 }
    ],
    resourceList: {
      enmatter: ['Cave Sap', 'Magerian', 'Typonolic', 'Garcen', 'Oil'],
      ores: ['Redulite', 'Rugaritz', 'Kanerium', 'Durulium', 'Gazzurdite', 'Belkar', 'Lysterium']
    },
    border: '30808,55037 31784,55101 32632,54829 32728,53773 32568,52813 31960,52701 30568,52701 30440,52861 30216,54125 30392,54509 30472,54909',
    addOffsetXY: true
  },
  { // Amethera Outback Land #62
    Name: 'Amethera Outback Land #62',
    id: '62',
    notes: '',
    huntingTax: 0.0,
    miningTax: 0.0,
    shoppingTax: 2.50,
    owner: 'Marcus Callender The Estate Broker',
    feederPos: '32327,51751,109',
    mobsList: [],
    resourceList: {
      enmatter: [''],
      ores: ['']
    },
    border: '30296,52541 30968,52621 31768,52589 32312,52461 32616,51933 32296,51037 31560,50749 30888,51021 30280,51453 30200,51917',
    addOffsetXY: true
  },
  { // Amethera Outback Land #59
    Name: 'Amethera Outback Land #59',
    id: '59',
    notes: '',
    huntingTax: 5.0,
    miningTax: 5.0,
    shoppingTax: 2.50,
    owner: 'McKenna Kenney Freeman',
    feederPos: '26871,52659,111',
    mobsList: [
      { name: 'Argonaut (North)', maturity: { min: 'Scout', max: 'Guardian' }, DensitySize: 5 },
      { name: 'Molisk (South-East)', maturity: { min: 'Adult', max: 'Scout' }, DensitySize: 5 }
    ],
    resourceList: {
      enmatter: ['Cave Sap', 'Typonolic', 'Magerian', 'Oil'],
      ores: ['Lysterium', 'Redulite', 'Rugaritz', 'Kanerium', 'Zanderium', 'Durulium', 'Gazzurdite', 'Iron']
    },
    border: '25944,54925 26632,54925 27272,54173 27720,53053 27000,52269 26664,52061 25480,52045 24808,52093 24584,52461 24616,52589 24904,52797 25336,53261',
    addOffsetXY: true
  },
  { // Amethera Outback Land #60
    Name: 'Amethera Outback Land #60',
    id: '60',
    notes: '',
    huntingTax: 0.0,
    miningTax: 0.0,
    shoppingTax: 2.50,
    owner: 'Calypso Official Emma',
    feederPos: '28975,53352,173',
    mobsList: [],
    resourceList: {
      enmatter: [''],
      ores: ['']
    },
    border: '29352,54029 29704,53885 29608,52221 29336,51869 28968,51757 28744,51965 28360,51885 27928,51517 27656,51517 27464,50893 27304,50861 26456,51293 26392,51437 26440,51565 26792,51805 26968,52093 27736,52749 27720,52909 27944,53101 28376,53261 28616,53405 28696,53613',
    addOffsetXY: true
  },
  { // Amethera Outback Land #30
    Name: 'Amethera Outback Land #30',
    id: '30',
    notes: '',
    huntingTax: 4.0,
    miningTax: 2.49,
    shoppingTax: 2.50,
    owner: 'Rachel MsPudding Hawkins',
    feederPos: '29025,51216,240',
    mobsList: [
      { name: 'Berycled', maturity: { min: 'Prowler', max: 'Stalker' }, DensitySize: 90 },
      { name: 'Combibo', maturity: { min: 'Prowler', max: 'Stalker' }, DensitySize: 90 },
      { name: 'Mourner', maturity: { min: 'Young', max: '' }, DensitySize: 5 }
    ],
    resourceList: {
      enmatter: [''],
      ores: ['']
    },
    border: '28216,51549 28568,51597 29416,51597 29432,51261 29544,50893 30152,50029 30040,49597 29688,49245 29112,49325 28696,49453 28392,49741 28184,50125 28024,50733 28040,51165',
    addOffsetXY: true
  },
  { // Emerald Lakes Mall Land
    Name: 'Emerald Lakes Mall Land',
    id: 'EmeraldLakesMall',
    notes: '',
    huntingTax: 3.95,
    miningTax: 3.95,
    shoppingTax: 2.50,
    owner: 'Onkel RobRoy Bob',
    feederPos: '31701,50222,104',
    mobsList: [
      { name: 'Molisk', maturity: { min: 'Young', max: 'Adult' }, DensitySize: 60 },
      { name: 'Shinkiba', maturity: { min: 'Young', max: 'Mature' }, DensitySize: 20 },
      { name: 'Warrior', maturity: { min: 'Gen 01', max: 'Gen 10' }, DensitySize: 50 },
      { name: 'Trooper', maturity: { min: 'Gen 01', max: 'Gen 10' }, DensitySize: 25 },
      { name: 'Eviscerator', maturity: { min: 'Gen 01', max: 'Gen 03' }, DensitySize: 5 },
      { name: 'Thorio Male (East)', maturity: { min: 'Weakling', max: 'Farmer' }, DensitySize: 10 },
      { name: 'Thorio Female (East)', maturity: { min: 'Weakling', max: 'Farmer' }, DensitySize: 10 },
      { name: 'Drone (East)', maturity: { min: 'Gen 01', max: 'Gen 03' }, DensitySize: 5 }
    ],
    resourceList: {
      enmatter: [''],
      ores: ['']
    },
    border: '32120,50893 32664,50781 32728,49773 32632,49261 32200,49165 30376,49197 30552,49581 31784,50717',
    addOffsetXY: true
  },
  { // Amethera Outback Land #61
    Name: 'Amethera Outback Land #61',
    id: '61',
    notes: '',
    huntingTax: 1.70,
    miningTax: 2.70,
    shoppingTax: 2.50,
    owner: 'Modified Akoz Power',
    feederPos: '26918,50741,231',
    mobsList: [
      { name: 'Tripudion (East)', maturity: { min: 'Young', max: 'Old' }, DensitySize: 25 },
      { name: 'Nexnecis (East)', maturity: { min: 'Young', max: 'Mature' }, DensitySize: 25 }
    ],
    resourceList: {
      enmatter: [''],
      ores: ['']
    },
    border: '25736,51149 26104,51085 26088,51197 26376,51053 26456,51117 26952,50925 27016,50781 27160,50717 27448,50845 27880,50701 28264,49773 28456,49165 26856,49165 26504,49437 25640,49757 25656,50765 25608,50829 25688,50973',
    addOffsetXY: true
  },
  { // Amethera LA7
    Name: 'Amethera LA7',
    id: 'LA7',
    notes: '',
    huntingTax: 0.0,
    miningTax: 0.0,
    shoppingTax: 2.50,
    owner: 'Marcus Callender The Estate Broker',
    feederPos: '18015,38643,123',
    mobsList: [
      { name: 'Araneatrox', maturity: { min: 'Young', max: 'Mature' }, DensitySize: 5 },
      { name: 'Itumatrox', maturity: { min: 'Young', max: '' }, DensitySize: 25 },
      { name: 'Bristlehog', maturity: { min: 'Young', max: 'Dominant' }, DensitySize: 35 }
    ],
    resourceList: {
      enmatter: [''],
      ores: ['']
    },
    border: '16800,39780 17296,40084 18400,40500 19200,40452 19440,40644 20016,40724 20112,38628 20048,38516 19456,38500 19424,38564 19168,38564 19232,38452 19216,38132 19584,37924 19472,37300 18976,36644 18720,36740 18704,36948 18880,37156 18720,37236 17760,36804 17680,36868 17600,37060 17392,37092 17360,37428 17216,37348 17200,36980 16992,36820 16688,36532 16480,37028 16544,37364 16976,38100 17264,38244 17376,38548 17600,38740 17712,38980 17920,39252 17840,39412 17472,39332 17168,39508 16992,39540',
    addOffsetXY: true
  },
  { // Amethera LA5
    Name: 'Amethera LA5',
    id: 'LA5',
    notes: '',
    huntingTax: 0.0,
    miningTax: 0.0,
    shoppingTax: 2.50,
    owner: 'Marcus Callender The Estate Broker',
    feederPos: '22735,39843,112',
    mobsList: [],
    resourceList: {
      enmatter: [''],
      ores: ['']
    },
    border: '20272,40692 20576,40756 20864,40532 21472,40740 21760,40756 22272,40676 22512,40660 22784,40516 23440,40084 23488,40148 23504,40292 23712,40436 24208,40532 24208,39988 23952,39572 23792,39396 23504,39252 23408,38852 23248,38676 23040,38708 22640,38356 22272,38388 21472,38100 21344,38132 21120,38388 21040,38596 20848,38548 20736,38564 20288,38996',
    addOffsetXY: true
  },
  { // Amethera LA11
    Name: 'Amethera LA11',
    id: 'LA11',
    notes: '',
    huntingTax: 0.0,
    miningTax: 0.0,
    shoppingTax: 2.50,
    owner: 'Marcus Callender The Estate Broker',
    feederPos: '18005,35522,144',
    mobsList: [
      { name: 'Cersumon', maturity: { min: 'Young', max: 'Mature' }, DensitySize: 25 },
      { name: 'Bristlehog', maturity: { min: 'Young', max: 'Dominant' }, DensitySize: 40 },
      { name: 'Mermoth', maturity: { min: 'Young', max: '' }, DensitySize: 15 }
    ],
    resourceList: {
      enmatter: [''],
      ores: ['']
    },
    border: '18080,36548 18288,36324 18256,35812 18064,35284 18240,35124 18336,34852 18832,34804 18992,34932 19536,34580 19888,34292 20112,34036 19920,33860 19632,34068 19408,33908 18688,34180 18224,34116 17968,34212 17648,34068 17296,34084 17056,34260 17008,34516 16672,34740 16576,35236 16784,35860 17376,36388',
    addOffsetXY: true
  },
  { // Amethera Outback Land #18
    Name: 'Amethera Outback Land #18',
    id: '18',
    notes: 'BIG Industries',
    huntingTax: 3.99,
    miningTax: 3.99,
    shoppingTax: 2.50,
    owner: 'Blastoise Meculus Yarlboro',
    feederPos: '22357,35414,169',
    mobsList: [
      { name: 'Primordial Longu', maturity: { min: 'Old Alpha', max: 'Slayer' }, DensitySize: 100 }
    ],
    resourceList: {
      enmatter: [''],
      ores: ['']
    },
    border: '19696,35732 20720,36324 21264,36564 22528,36628 24192,36420 24432,35636 24368,33876 23536,33780 22384,33124 21776,33044 20576,33940 20096,34756 20112,35492',
    addOffsetXY: true
  },
  { // Amethera LA10
    Name: 'Amethera LA10',
    id: 'LA10',
    notes: '',
    huntingTax: 0.0,
    miningTax: 0.0,
    shoppingTax: 2.50,
    owner: 'Marcus Callender The Estate Broker',
    feederPos: '18653,26909,161',
    mobsList: [
      { name: 'Atrox', maturity: { min: 'Young', max: 'Mature' }, DensitySize: 20 },
      { name: 'Mermoth', maturity: { min: 'Young', max: '' }, DensitySize: 25 },
      { name: 'Itumatrox', maturity: { min: 'Young', max: 'Mature' }, DensitySize: 25 },
      { name: 'Exarosaur', maturity: { min: 'Young', max: 'Dominant' }, DensitySize: 15 }
    ],
    resourceList: {
      enmatter: [''],
      ores: ['']
    },
    border: '16848,28832 18928,29168 19648,28592 20464,28704 20624,28384 20448,27920 20928,27008 20912,26768 20560,26592 20048,25712 18784,25648 18128,25808 17952,25904 17552,26720 17632,27216 17808,27568',
    addOffsetXY: true
  },
  { // Amethera Outback Land #40
    Name: 'Amethera Outback Land #40',
    id: '40',
    notes: '',
    huntingTax: 4.0,
    miningTax: 4.0,
    shoppingTax: 2.50,
    owner: 'Leshrac Holynight Brevin',
    feederPos: '23398,27970,124',
    mobsList: [
      { name: 'Atrox', maturity: { min: 'Guardian', max: 'Old Alpha' }, DensitySize: 95 }
    ],
    resourceList: {
      enmatter: [''],
      ores: ['']
    },
    border: '22368,28448 23952,29360 24320,29376 24400,29248 24448,28192 24368,27456 23824,27328 23152,27280 22560,27280 22176,27680 22096,28032',
    addOffsetXY: true
  },
  { // Akmuul SouthWest OLA39
    Name: 'Akmuul SouthWest OLA39',
    id: 'AkmuulSouthWest',
    notes: '',
    huntingTax: 2.0,
    miningTax: 0.0,
    shoppingTax: 2.50,
    owner: 'Stan Sky Kazakh',
    feederPos: '23970,26628,146',
    mobsList: [
      { name: 'Neconu', maturity: { min: 'Young', max: 'Guardian' }, DensitySize: 95.9 }
    ],
    resourceList: {
      enmatter: ['Alicenies', 'Lytairian', 'Oil', 'Devil\'s', 'Angelic'],
      ores: ['Belkar', 'Blausariam', 'Erdorium', 'Lysterium', 'Kanerium']
    },
    border: '21472,27072 23312,27072 24480,27056 24480,24816 23760,24784 22656,25088 21648,25536 21184,26512',
    addOffsetXY: true
  },
  { // Amethera Outback Land #13
    Name: 'Amethera Outback Land #13',
    id: '13',
    notes: '',
    huntingTax: 0.0,
    miningTax: 0.0,
    shoppingTax: 2.50,
    owner: 'MindArk Official Annex',
    feederPos: '25216,31823,136',
    mobsList: [
      { name: 'Kingfisher', maturity: { min: 'Young', max: '' }, DensitySize: 15 }
    ],
    resourceList: {
      enmatter: [''],
      ores: ['']
    },
    border: '24704,32688 26608,32688 26592,32400 26464,31632 26000,30720 24928,30544 24624,30656 24608,31776',
    addOffsetXY: true
  },
  { // Amethera Outback Land #09
    Name: 'Amethera Outback Land #09',
    id: '09',
    notes: '',
    huntingTax: 3.75,
    miningTax: 3.75,
    shoppingTax: 2.50,
    owner: 'Anders SanBoX Nielsen',
    feederPos: '26935,31902,151',
    mobsList: [
      { name: 'Estophyl', maturity: { min: 'Young', max: 'Mature' }, DensitySize: 25 },
      { name: 'Shinkiba', maturity: { min: 'Young', max: 'Mature' }, DensitySize: 10 }
    ],
    resourceList: {
      enmatter: [''],
      ores: ['']
    },
    border: '26720,32656 27936,32512 27984,31824 27840,31552 27344,31120 26608,30752 26480,31584',
    addOffsetXY: true
  },
  { // Amethera Outback Land #14
    Name: 'Amethera Outback Land #14',
    id: '14',
    notes: '',
    huntingTax: 10.0,
    miningTax: 3.10,
    shoppingTax: 2.50,
    owner: 'Ze T\'Raider Jo',
    feederPos: '28487,31761,232',
    mobsList: [
      { name: 'Gokibusagi', maturity: { min: 'Young', max: '' }, DensitySize: 40 },
      { name: 'Longu', maturity: { min: 'Young', max: '' }, DensitySize: 25 },
      { name: 'Atrox', maturity: { min: 'Young', max: '' }, DensitySize: 5 }
    ],
    resourceList: {
      enmatter: ['Oil'],
      ores: ['Azzurdite', 'Narcanisum', 'Niksarium', 'Lysterium']
    },
    border: '28160,32000 28896,32080 29168,31664 29296,30864 29152,30832 29120,30368 28464,30000 28400,30768 27872,31536',
    addOffsetXY: true
  },
  { // Amethera Outback Land #15
    Name: 'Amethera Outback Land #15',
    id: '15',
    notes: '',
    huntingTax: 5.0,
    miningTax: 4.0,
    shoppingTax: 2.50,
    owner: 'Jos Bernz Bernz',
    feederPos: '31045,30027,202',
    mobsList: [
      { name: 'Trilomite', maturity: { min: 'Young', max: '' }, DensitySize: 55 },
      { name: 'Shinkiba', maturity: { min: 'Young', max: '' }, DensitySize: 15 },
      { name: 'Berycled', maturity: { min: 'Young', max: 'Mature' }, DensitySize: 15 }
    ],
    resourceList: {
      enmatter: [''],
      ores: ['']
    },
    border: '29408,30608 31232,30800 31856,30224 31184,29024 30880,29344 30800,29728 29472,29328 29056,30192',
    addOffsetXY: true
  },
  { // Greenthorne
    Name: 'Greenthorne',
    id: 'Greenthorne',
    notes: '',
    huntingTax: 1.0,
    miningTax: 0.0,
    shoppingTax: 2.50,
    owner: 'N/A',
    feederPos: 'N/A',
    mobsList: [
      { name: 'Formidon', maturity: { min: 'Old Alpha', max: 'Stalker' }, DensitySize: 95 }
    ],
    resourceList: {
      enmatter: [''],
      ores: ['']
    },
    border: '29392,32640 30736,32608 32592,32672 32720,31984 32384,31472 32416,30896 32000,30400 31232,30816 29440,30848 29216,31600',
    addOffsetXY: true
  },
  { // Amethera Outback Land #20
    Name: 'Amethera Outback Land #20',
    id: '20',
    notes: '',
    huntingTax: 3.88,
    miningTax: 2.34,
    shoppingTax: 2.50,
    owner: 'Modified Akoz Power',
    feederPos: '35619,27951,160',
    mobsList: [
      { name: 'Longu', maturity: { min: 'Young', max: 'Mature' }, DensitySize: 30 },
      { name: 'Trilomite', maturity: { min: 'Provider', max: 'Dominant' }, DensitySize: 5 },
      { name: 'Atrax', maturity: { min: 'Provider', max: 'Guardian' }, DensitySize: 5 }
    ],
    resourceList: {
      enmatter: [''],
      ores: ['']
    },
    border: '33926,29770 35206,29882 36374,29402 36134,27642 34838,27626 33958,28234 33606,29050',
    addOffsetXY: true
  },
  { // Amethera Outback Land #19
    Name: 'Amethera Outback Land #19',
    id: '19',
    notes: '',
    huntingTax: 3.75,
    miningTax: 3.75,
    shoppingTax: 2.50,
    owner: 'Michael Ketch Arcanum',
    feederPos: '35251,26820,113',
    mobsList: [
      { name: 'Neconu', maturity: { min: 'Young', max: 'Mature' }, DensitySize: 40 },
      { name: 'Trilomite', maturity: { min: 'Dominant', max: '' }, DensitySize: 5 }
    ],
    resourceList: {
      enmatter: ['Devil\'s', 'Binary', 'Garcen', 'Growth', 'Oil'],
      ores: ['Belkar', 'Caldorite', 'Lysterium', 'Gold']
    },
    border: '33606,27594 35814,27514 36246,27290 36198,26522 35606,26154 34086,26538 33478,26810 33350,27290',
    addOffsetXY: true
  },
  { // Red Sands Forest
    Name: 'Red Sands Forest',
    id: 'RedSandsForest',
    notes: '',
    huntingTax: 1.0,
    miningTax: 1.0,
    shoppingTax: 2.50,
    owner: 'N/A',
    feederPos: 'N/A',
    mobsList: [
      { name: 'Cersumon', maturity: { min: 'Prowler', max: 'Stalker' }, DensitySize: 95 },
    ],
    resourceList: {
      enmatter: [''],
      ores: ['']
    },
    border: '36422,28282 38358,29114 39926,28938 39526,27354 39094,27610 38838,27370 37318,27002 37318,26778 36886,26586 36502,27082',
    addOffsetXY: true
  },
  { // Amethera Outback Land #10
    Name: 'Amethera Outback Land #10',
    id: '10',
    notes: '',
    huntingTax: 5.50,
    miningTax: 5.50,
    shoppingTax: 2.50,
    owner: 'Lee Zhorgul Salmon',
    feederPos: '38816,29509,118',
    mobsList: [
      { name: 'Atrox', maturity: { min: 'Young', max: '' }, DensitySize: 95 }
    ],
    resourceList: {
      enmatter: [''],
      ores: ['']
    },
    border: '38438,30154 39174,30410 39462,29578 39142,29146 38406,29290 38262,29738',
    addOffsetXY: true
  },
  { // Cape Carramone
    Name: 'Cape Carramone',
    id: 'CapeCarramone',
    notes: '',
    huntingTax: 1.0,
    miningTax: 1.0,
    shoppingTax: 2.50,
    owner: 'N/A',
    feederPos: 'N/A',
    mobsList: [
      { name: 'Gradivore', maturity: { min: 'Alpha', max: 'Stalker' }, DensitySize: 99 },
      { name: 'Atrox', maturity: { min: 'Provider', max: 'Dominant' }, DensitySize: 20 },
      { name: 'Exarosaur', maturity: { min: 'Mature', max: 'Dominant' }, DensitySize: 5 }
    ],
    resourceList: {
      enmatter: [''],
      ores: ['']
    },
    border: '34198,32634 34774,32666 35510,31930 35478,31770 35142,31642 35446,31306 35542,30986 35206,30458 33878,30666 33750,31034 33398,31210 33350,31418 33158,31514 33158,31770 33510,31850',
    addOffsetXY: true
  },
  { // Amethera LA18
    Name: 'Amethera LA18',
    id: 'LA18',
    notes: '',
    huntingTax: 0.0,
    miningTax: 0.0,
    shoppingTax: 2.50,
    owner: 'Marcus Callender The Estate Broker',
    feederPos: '36078,31067,104',
    mobsList: [
      { name: 'Kingfisher', maturity: { min: 'Young', max: 'Mature' }, DensitySize: 50 },
      { name: 'Oculus', maturity: { min: 'Young', max: 'Mature' }, DensitySize: 50 }
    ],
    resourceList: {
      enmatter: [''],
      ores: ['']
    },
    border: '36118,32314 37190,31978 37094,31018 36870,31018 36694,30858 36438,30298 35846,30170 35686,31306 36038,31514 35878,31786 35702,31786 35702,32010',
    addOffsetXY: true
  },
  { // Takuta Plateau
    Name: 'Takuta Plateau',
    id: 'TakutaPlateau',
    notes: '',
    huntingTax: 1.50,
    miningTax: 2.0,
    shoppingTax: 2.50,
    owner: 'N/A',
    feederPos: 'N/A',
    mobsList: [
      { name: 'Araneatrox', maturity: { min: 'Young', max: 'Mature' }, DensitySize: 95 }
    ],
    resourceList: {
      enmatter: [''],
      ores: ['']
    },
    border: '37254,32218 38774,32682 39686,32570 40006,32314 39002.81,30760.62 38726,30682 38278,30682 38054,30330 37702,30218 37446,30218 37190,30458',
    addOffsetXY: true
  },
  { // Amethera LA3
    Name: 'Amethera LA3',
    id: 'LA3',
    notes: '',
    huntingTax: 0.0,
    miningTax: 0.0,
    shoppingTax: 2.50,
    owner: 'Marcus Callender The Estate Broker',
    feederPos: '39129,33373,189',
    mobsList: [
      { name: 'Falxangius', maturity: { min: 'Young', max: '' }, DensitySize: 15 },
      { name: 'Atrox', maturity: { min: 'Young', max: 'Guardian' }, DensitySize: 15 },
      { name: 'Caudatergus', maturity: { min: 'Old', max: 'Guardian' }, DensitySize: 5 }
    ],
    resourceList: {
      enmatter: [''],
      ores: ['']
    },
    border: '39286,35338 40022,35466 40886,35290 40886,33162 40838,32874 39846,32874 39334,32938 38182,32858 37238,33322 37526,34058 37814,33818 39110,33882 39302,34010 39638,34026 39670,34698',
    addOffsetXY: true
  },
  { // Amethera LA3
    Name: 'Amethera LA19',
    id: 'LA19',
    notes: '',
    huntingTax: 0.0,
    miningTax: 0.0,
    shoppingTax: 2.50,
    owner: 'Marcus Callender The Estate Broker',
    feederPos: '40130,36360,113',
    mobsList: [
      { name: 'Atrox', maturity: { min: 'Gaurdian', max: 'Dominant' }, DensitySize: 15 },
      { name: 'Fungoid', maturity: { min: 'Young', max: 'Mature' }, DensitySize: 30 },
      { name: 'Gradivore', maturity: { min: 'Young', max: 'Mature' }, DensitySize: 30 }
    ],
    resourceList: {
      enmatter: [''],
      ores: ['']
    },
    border: '40407,37826 40855,37762 40887,36418 40791,35650 39671,35618 39063,35858 39159,36274 40071,37666',
    addOffsetXY: true
  },
  { // The Land of Oz
    Name: 'The Land of Oz',
    id: 'Oz',
    notes: '',
    huntingTax: 3.99,
    miningTax: 3.99,
    shoppingTax: 2.50,
    owner: 'Josef Fruktis Petrik',
    feederPos: '36509,35272,171',
    mobsList: [
      { name: 'Fungoid', maturity: { min: 'Young', max: '' }, DensitySize: 5 },
      { name: 'Atrax', maturity: { min: 'Young', max: '' }, DensitySize: 15 },
      { name: 'Merp', maturity: { min: 'Young', max: '' }, DensitySize: 10 },
      { name: 'Neconu', maturity: { min: 'Young', max: '' }, DensitySize: 20 }
    ],
    resourceList: {
      enmatter: [''],
      ores: ['']
    },
    border: '36198,35530 36550,35546 37206,35642 37542,35514 37558,35146 37350,34682 36902,34474 36454,34506 36182,34714',
    addOffsetXY: true
  },
  { // Amethera Outback Land #02
    Name: 'Amethera Outback Land #02',
    id: '02',
    notes: '',
    huntingTax: 4.0,
    miningTax: 4.0,
    shoppingTax: 2.50,
    owner: 'Rachel MsPudding Hawkins',
    feederPos: '35746,35162,102',
    mobsList: [
      { name: 'Atrox', maturity: { min: 'Guardian', max: 'Alpha' }, DensitySize: 85 }
    ],
    resourceList: {
      enmatter: ['Alicenies', 'Lytairian', 'Oil', 'Devil\'s', 'Angelic'],
      ores: ['Belkar', 'Blausariam', 'Erdorium', 'Lysterium', 'Kanerium']
    },
    border: '34934,35130 35318,35466 35846,35610 36118,35578 36150,35354 36134,34586 36550,34346 36566,34010 36214,33898 35862,34042 35382,34122 35062,34282 34950,34858',
    addOffsetXY: true
  },
  { // Amethera LA17
    Name: 'Amethera LA17',
    id: 'LA17',
    notes: '',
    huntingTax: 0.0,
    miningTax: 0.0,
    shoppingTax: 2.50,
    owner: 'Marcus Callender The Estate Broker',
    feederPos: '34526,33334,341',
    mobsList: [
      { name: 'Grand Formidon', maturity: { min: 'Young', max: 'Old' }, DensitySize: 25 },
      { name: 'Gradivore', maturity: { min: 'Young', max: '' }, DensitySize: 5 }
    ],
    resourceList: {
      enmatter: [''],
      ores: ['']
    },
    border: '33494,34522 33686,34570 33798,34506 33958,34634 34166,34538 34230,34410 34646,34074 34790,33834 34870,33338 34614,33258 34486,33306 34390,33370 33654,33434 33350,33178 32982,33146 32886,33578 32966,33738 33494,33722 33558,34298',
    addOffsetXY: true
  },
  { // Amethera LA6
    Name: 'Amethera LA6',
    id: 'LA6',
    notes: '',
    huntingTax: 0.0,
    miningTax: 0.0,
    shoppingTax: 2.50,
    owner: 'Marcus Callender The Estate Broker',
    feederPos: '31710,33940,242',
    mobsList: [
      { name: 'Thorifoid Berserker', maturity: { min: 'Inept', max: '' }, DensitySize: 60 }
    ],
    resourceList: {
      enmatter: [''],
      ores: ['']
    },
    border: '30870,34650 31382,34650 31830,34890 32534,34842 32662,34666 32662,34186 32502,34154 32454,33530 32310,33386 32246,33434 32182,33370 32102,33354 32054,33306 32150,33178 32614,33130 32662,32874 31846,32858 31094,33082 30918,32874 30694,33402 30614,34298',
    addOffsetXY: true
  },
  { // Amethera LA13
    Name: 'Amethera LA13',
    id: 'LA13',
    notes: '',
    huntingTax: 0.0,
    miningTax: 0.0,
    shoppingTax: 2.50,
    owner: 'Marcus Callender The Estate Broker',
    feederPos: '28849,33541,165',
    mobsList: [
      { name: 'Thorifoid Shaman', maturity: { min: 'Inept', max: 'Neophyte' }, DensitySize: 20 }
    ],
    resourceList: {
      enmatter: [''],
      ores: ['']
    },
    border: '28118,34442 28742,34522 29798,34170 29990,33610 29782,33290 29846,32922 29286,32890 28598,33386 28406,33210 28246,33338 27942,32842 26534,32906 26710,33386',
    addOffsetXY: true
  },
  { // Amethera Outback Land #07
    Name: 'Amethera Outback Land #07',
    id: '07',
    notes: 'BIG Industries',
    huntingTax: 3.99,
    miningTax: 3.99,
    shoppingTax: 2.50,
    owner: 'Blastoise Meculus Yarlboro',
    feederPos: '25313,35936,111',
    mobsList: [
      { name: 'Scaboreas', maturity: { min: 'Young', max: 'Mature' }, DensitySize: 99 }
    ],
    resourceList: {
      enmatter: [''],
      ores: ['']
    },
    border: '25350,37098 26086,36330 25942,35898 25542,35626 24982,35946 24678,36218 24678,36586 24822,36794',
    addOffsetXY: true
  },
  { // Amethera Outback Land #16
    Name: 'Amethera Outback Land #16',
    id: '16',
    notes: '',
    huntingTax: 3.90,
    miningTax: 2.80,
    shoppingTax: 2.50,
    owner: 'Modified Akoz Power',
    feederPos: '29687,35802,197',
    mobsList: [
      { name: 'Ambulimax', maturity: { min: 'Young', max: 'Mature' }, DensitySize: 95 }
    ],
    resourceList: {
      enmatter: ['Oil'],
      ores: ['Kanerium', 'Lysterium', 'Terrudite', 'Cumbriz', 'Tridenite']
    },
    border: '29254,36842 30102,36842 29862,35546 29174,35194 28566,35082 27830,34762 27782,35562 29110,36042',
    addOffsetXY: true
  },
  { // Amethera Outback Land #08
    Name: 'Amethera Outback Land #08',
    id: '08',
    notes: '',
    huntingTax: 3.90,
    miningTax: 3.90,
    shoppingTax: 2.50,
    owner: 'Quin-jao Rei Fei-tzu',
    feederPos: '29630,37882,224',
    mobsList: [
      { name: 'Formidon', maturity: { min: 'Young', max: 'Mature' }, DensitySize: 25 }
    ],
    resourceList: {
      enmatter: [''],
      ores: ['']
    },
    border: '28726,38346 29270,38442 29798,38330 29974,37850 29670,37354 29206,37338 28678,37466 28406,37946',
    addOffsetXY: true
  },
  { // Amethera Outback Land #26
    Name: 'Amethera Outback Land #26',
    id: '26',
    notes: 'BIG Industries',
    huntingTax: 3.99,
    miningTax: 3.99,
    shoppingTax: 2.50,
    owner: 'Blastoise Meculus Yarlboro',
    feederPos: '27638,40359,158',
    mobsList: [
      { name: 'Allophyl', maturity: { min: 'Old Alpha', max: 'Stalker' }, DensitySize: 90 }
    ],
    resourceList: {
      enmatter: [''],
      ores: ['']
    },
    border: '26790,40890 27606,40874 28406,40618 28982,40042 28790,39354 28438,38986 27558,38746 26758,38698 25574,39146',
    addOffsetXY: true
  },
  { // Amethera Outback Land #23
    Name: 'Amethera Outback Land #23',
    id: '23',
    notes: 'Entropiafund',
    huntingTax: 4.50,
    miningTax: 3.50,
    shoppingTax: 2.50,
    owner: 'Toni Chiee Lahderinne',
    feederPos: '30839,40303,144',
    mobsList: [
      { name: 'Berycled', maturity: { min: 'Old Alpha', max: 'Stalker' }, DensitySize: 95 }
    ],
    resourceList: {
      enmatter: [''],
      ores: ['']
    },
    border: '29670,40842 30198,40890 30806,40842 30902,40522 30822,39914 30854,39562 30694,39066 30918,38874 30230,38666 29622,38826 29430,39386 29670,39978 29462,40394',
    addOffsetXY: true
  },
  { // Amethera Outback Land #24
    Name: 'Amethera Outback Land #24',
    id: '24',
    notes: 'Entropiafund',
    huntingTax: 3.99,
    miningTax: 3.50,
    shoppingTax: 2.50,
    owner: 'Toni Chiee Lahderinne',
    feederPos: '30986,40118,214',
    mobsList: [
      { name: 'Ambulimax', maturity: { min: 'Young', max: 'Mature' }, DensitySize: 90 }
    ],
    resourceList: {
      enmatter: ['Angelic', 'Alicenies', 'Force', 'Oil'],
      ores: ['Kanerium', 'Niksarium', 'Terrudite', 'Cobalt', 'Iron', 'Blausariam', 'Cumbriz', 'Zinc', 'Erdorium', 'Lysterium']
    },
    border: '31046,40858 32454,40874 32470,40714 32214,40634 32198,40410 32326,40298 32326,39754 32070,39530 32070,39274 32342,39114 32358,38810 31062,38618 31062,38858 31590,39194 31446,39498 31094,39498 30918,39962',
    addOffsetXY: true
  },
  { // Farsight Plains
    Name: 'Farsight Plains',
    id: 'FarsightPlains',
    notes: '',
    huntingTax: 2.0,
    miningTax: 2.0,
    shoppingTax: 2.50,
    owner: 'N/A',
    feederPos: 'N/A',
    mobsList: [
      { name: 'Oculus', maturity: { min: 'Prowler', max: 'Stalker' }, DensitySize: 95 }
    ],
    resourceList: {
      enmatter: [''],
      ores: ['']
    },
    border: '30374,38426 31798,38634 32390,38298 32118,37914 32342,37370 32374,36554 31494,36282 30902,36554 30134,37194',
    addOffsetXY: true
  },
  { // Amethera Outback Land #17
    Name: 'Amethera Outback Land #17',
    id: '17',
    notes: '',
    huntingTax: 3.90,
    miningTax: 3.90,
    shoppingTax: 2.50,
    owner: 'Jok star de PE LeMagniFiK',
    feederPos: '31576,35755,183',
    mobsList: [
      { name: 'Ambulimax', maturity: { min: 'Old Alpha', max: 'Stalker' }, DensitySize: 95 }
    ],
    resourceList: {
      enmatter: ['Oil', 'Garcen'],
      ores: ['Alferix', 'Belkar', 'Blausariam', 'Cumbriz', 'Kanerium', 'Lysterium', 'Terrudite', 'Tridenite']
    },
    border: '31078,36106 31606,36282 32310,36362 32710,36122 32678,35098 32006,35162 31910,35306 31734,35114 31094,35130',
    addOffsetXY: true
  },
  { // Amethera Outback Land #03
    Name: 'Amethera Outback Land #03',
    id: '03',
    notes: '',
    huntingTax: 3.0,
    miningTax: 3.0,
    shoppingTax: 2.50,
    owner: 'Hieronyme Lesolitaire Thorton',
    feederPos: '33519,36272,341',
    mobsList: [
      { name: 'Longu', maturity: { min: 'Young', max: '' }, DensitySize: 30 }
    ],
    resourceList: {
      enmatter: [''],
      ores: ['']
    },
    border: '33590,36682 34118,36602 34166,35962 34086,35482 33638,35418 33110,35546 33046,36090 33174,36490',
    addOffsetXY: true
  },
  { // Amethera Outback Land #01
    Name: 'Amethera Outback Land #01',
    id: '01',
    notes: 'BIG Industries',
    huntingTax: 3.99,
    miningTax: 3.99,
    shoppingTax: 2.50,
    owner: 'Blastoise Meculus Yarlboro',
    feederPos: '34727,36318,202',
    mobsList: [
      { name: 'Traeskeron', maturity: { min: 'Prowler', max: 'Stalker' }, DensitySize: 95 },
      { name: 'Equus', maturity: { min: 'Young', max: '' }, DensitySize: 10 },
      { name: 'Molisk', maturity: { min: 'Young', max: '' }, DensitySize: 5 }
    ],
    resourceList: {
      enmatter: [''],
      ores: ['']
    },
    border: '34438,36522 34822,36618 35318,36602 35382,36090 35318,35626 35142,35610 34598,35210 34214,35306 34246,36058',
    addOffsetXY: true
  },
  { // Amethera Outback Land #05
    Name: 'Amethera Outback Land #05',
    id: '05',
    notes: '',
    huntingTax: 0.0,
    miningTax: 0.0,
    shoppingTax: 2.50,
    owner: 'MindArk Official Annex',
    feederPos: '36500,35930,121',
    mobsList: [
      { name: 'Longu', maturity: { min: 'Young', max: '' }, DensitySize: 40 }
    ],
    resourceList: {
      enmatter: [''],
      ores: ['']
    },
    border: '36246,37130 36854,37114 36918,36890 37062,36842 37142,36762 37270,36762 37414,36202 37142,35914 36678,35786 36374,35802 36166,36058 36166,36618',
    addOffsetXY: true
  },
  { // Amethera Outback Land #12
    Name: 'Amethera Outback Land #12',
    id: '12',
    notes: '',
    huntingTax: 5.0,
    miningTax: 5.0,
    shoppingTax: 2.50,
    owner: 'EP farmer Farmer Joe EP REP',
    feederPos: '33809,37722,202',
    mobsList: [
      { name: 'Trilomite', maturity: { min: 'Young', max: 'Old' }, DensitySize: 15 }
    ],
    resourceList: {
      enmatter: [''],
      ores: ['']
    },
    border: '33014,38970 33286,39050 33654,38954 33862,38554 34678,38282 34678,37962 34534,37818 34662,37642 34630,37322 34822,37274 34774,36890 34070,36826 33478,37018 33638,37626 33686,38042 33158,38538',
    addOffsetXY: true
  },
  { // Amethera Outback Land #11
    Name: 'Amethera Outback Land #11',
    id: '11',
    notes: '',
    huntingTax: 3.75,
    miningTax: 3.75,
    shoppingTax: 2.50,
    owner: 'Aleph Levi Benzait',
    feederPos: '34556,39382,259',
    mobsList: [
      { name: 'Argonaut', maturity: { min: 'Young', max: '' }, DensitySize: 30 }
    ],
    resourceList: {
      enmatter: ['Garcen', 'Growth', 'Oil', 'Alicenies', 'Dunkel'],
      ores: ['Narcanisum', 'Frigulite', 'Zinc', 'Gold', 'Lysterium']
    },
    border: '33862,40266 34134,40074 34518,39610 34790,38986 35734,38970 36022,38170 35590,37898 35126,38122 34838,38394 34262,38490 33654,39530 33766,40106',
    addOffsetXY: true
  },
  { // Amethera Outback Land #06
    Name: 'Amethera Outback Land #06',
    id: '06',
    notes: 'Owner - Eugenio Anhithe Wilde',
    huntingTax: 3.99,
    miningTax: 3.99,
    shoppingTax: 2.50,
    owner: 'Eugenio Anhithe Wilde',
    feederPos: '37607,39379,132',
    mobsList: [
      { name: 'Longu', maturity: { min: 'Young', max: 'Dominant' }, DensitySize: 90 }
    ],
    resourceList: {
      enmatter: ['Growth', 'Oil', 'Garcen', 'Dunkel'],
      ores: ['Niksarium', 'Narcanisum', 'Gazzurdite', 'Azzurdite', 'Zinc', 'Alferix', 'Gold', 'Vesperdite', 'Lysterium']
    },
    border: '37382,39658 37766,39850 38070,39802 38150,39578 38054,39306 38278,39066 38502,39226 38742,39210 39014,38858 39046,37914 38566,37898 38006,38426 37910,38698 37558,38858 37382,39002',
    addOffsetXY: true
  },
  { // Southshade Valley
    Name: 'Southshade Valley',
    id: 'SouthshadeValley',
    notes: '',
    huntingTax: 1.50,
    miningTax: 2.0,
    shoppingTax: 2.50,
    owner: 'N/A',
    feederPos: 'N/A',
    mobsList: [
      { name: 'Kingfisher', maturity: { min: 'Young', max: 'Mature' }, DensitySize: 95 }
    ],
    resourceList: {
      enmatter: [''],
      ores: ['']
    },
    border: '32966,43754 34262,43786 34550,43530 34774,43050 34838,41786 34678,41162 33926,41034 33046,41242 32822,41898 32870,43258',
    addOffsetXY: true
  },
  { // Miwak Mountain Pass
    Name: 'Miwak Mountain Pass',
    id: 'MiwakMountainPass',
    notes: '',
    huntingTax: 1.0,
    miningTax: 1.0,
    shoppingTax: 2.50,
    owner: 'N/A',
    feederPos: 'N/A',
    mobsList: [
      { name: 'Thorifoid Berserker', maturity: { min: 'Master', max: 'Legendary' }, DensitySize: 90 },
      { name: 'Thorifoid Shaman', maturity: { min: 'Master', max: 'Legendary ' }, DensitySize: 90 },
      { name: 'Thorio Male', maturity: { min: 'Housecarl', max: 'Overseer' }, DensitySize: 90 },
      { name: 'Thorio Female', maturity: { min: 'Housecarl', max: 'Overseer' }, DensitySize: 90 }
    ],
    resourceList: {
      enmatter: [''],
      ores: ['']
    },
    border: '35142,43674 35302,43738 35574,43674 35814,42922 36966,43146 37414,41914 37206,41146 35190,41114 35030,42170',
    addOffsetXY: true
  },
  { // Amethera Outback Land #66
    Name: 'Amethera Outback Land #66',
    id: '66',
    notes: '',
    huntingTax: 0.0,
    miningTax: 0.0,
    shoppingTax: 2.50,
    owner: 'MindArk Official Assistant Explorer',
    feederPos: '38750,43186,135',
    mobsList: [
      { name: 'Formidon (South/South-West)', maturity: { min: 'Young', max: 'Guardian' }, DensitySize: 95 },
      { name: 'Caudatergus (North-East)', maturity: { min: 'Dominant', max: 'Stalker' }, DensitySize: 95 },
      { name: 'Tantillion (East/South-East)', maturity: { min: 'Dominant', max: 'Stalker' }, DensitySize: 90 },
      { name: 'Neconu (Mid)', maturity: { min: 'Mature', max: 'Guardian' }, DensitySize: 80 }
    ],
    resourceList: {
      enmatter: [''],
      ores: ['']
    },
    border: '38742,43562 39910,43306 40054,42474 40166,42394 40118,42138 39974,42026 39926,41626 39686,40986 38182,41050 37798,41498 37862,42554 38342,43370',
    addOffsetXY: true
  },
  { // Amethera Outback Land #65
    Name: 'Amethera Outback Land #65',
    id: '65',
    notes: '',
    huntingTax: 3.99,
    miningTax: 3.50,
    shoppingTax: 2.50,
    owner: 'Toni Chiee Lahderinne',
    feederPos: '39736,45606,129',
    mobsList: [
      { name: 'Shinkiba', maturity: { min: 'Young', max: '' }, DensitySize: 40 }
    ],
    resourceList: {
      enmatter: ['Alicenies', 'Typonolic', 'Magerian', 'Oil', 'Growth'],
      ores: ['Belkar', 'Dianum', 'Terrudite', 'Lysterium', 'Copper']
    },
    border: '40150,47354 40518,47402 40662,47226 40726,47066 40726,46890 40678,46794 40854,46602 40854,45082 40902,44714 40806,44426 39814,44538 39590,44714 39542,45242 39334,45450 39430,45834 39398,45994 39622,46570',
    addOffsetXY: true
  },
  { // Vermillion Fields
    Name: 'Vermillion Fields',
    id: 'VermillionFields',
    notes: '',
    huntingTax: 1.50,
    miningTax: 2.0,
    shoppingTax: 2.50,
    owner: 'N/A',
    feederPos: 'N/A',
    mobsList: [
      { name: 'Falxangius', maturity: { min: 'Old Alpha', max: 'Stalker' }, DensitySize: 95 }
    ],
    resourceList: {
      enmatter: [''],
      ores: ['']
    },
    border: '32790,46666 33094,46986 33142,46778 33222,46698 33542,46906 33750,46970 33734,47098 33846,47114 33974,47242 34070,47578 34406,47818 35238,47978 35782,48186 36342,48026 36646,47466 36662,45610 36518,44762 36118,44378 36038,44090 35750,43994 34790,43962 32806,44026 32822,46282',
    addOffsetXY: true
  },
  { // Amethera Outback Land #25
    Name: 'Amethera Outback Land #25',
    id: '25',
    notes: '',
    huntingTax: 3.99,
    miningTax: 2.50,
    shoppingTax: 2.50,
    owner: 'Gemini Nana Prudential',
    feederPos: '31631,46352,295',
    mobsList: [
      { name: 'Ossecollum', maturity: { min: 'Old', max: 'Provider' }, DensitySize: 10 }
    ],
    resourceList: {
      enmatter: [''],
      ores: ['']
    },
    border: '31494,47178 32662,47162 32662,45946 32310,45130 31894,45018 30950,44202 30054,45050 30790,46650',
    addOffsetXY: true
  },
  { // Amethera Outback Land #27
    Name: 'Amethera Outback Land #27',
    id: '27',
    notes: '',
    huntingTax: 3.90,
    miningTax: 3.90,
    shoppingTax: 2.50,
    owner: 'Game Development Lecturer',
    feederPos: '27931,45389,210',
    mobsList: [
      { name: 'Molisk', maturity: { min: 'Young', max: 'Adult' }, DensitySize: 10 },
      { name: 'Tripudion', maturity: { min: 'Young', max: '' }, DensitySize: 10 }
    ],
    resourceList: {
      enmatter: [''],
      ores: ['']
    },
    border: '27350,46634 28278,46538 29126,46682 29542,46474 29686,45914 29398,43786 27974,43978 27366,43722 26886,44170 27190,44762 27078,45450',
    addOffsetXY: true
  },
  { // Amethera Outback Land #64
    Name: 'Amethera Outback Land #64',
    id: '64',
    notes: '',
    huntingTax: 3.99,
    miningTax: 3.99,
    shoppingTax: 2.50,
    owner: 'F1ngers ShiityLiLMongo',
    feederPos: '27862,42745,139',
    mobsList: [
      { name: 'Neconu', maturity: { min: 'Old Alpha', max: 'Stalker' }, DensitySize: 90 }
    ],
    resourceList: {
      enmatter: [''],
      ores: ['']
    },
    border: '26835,43924 27331,43444 27939,43796 29251,43636 29891,42996 28483,41876 26739,42132 26179,42340 26403,43524',
    addOffsetXY: true
  },
  { // Amethera Outback Land #63
    Name: 'Amethera Outback Land #63',
    id: '63',
    notes: '',
    huntingTax: 0.0,
    miningTax: 0.0,
    shoppingTax: 2.50,
    owner: 'Marcus Callender The Estate Broker',
    feederPos: '28092,48439,217',
    mobsList: [
      { name: 'Nexnecis', maturity: { min: 'Young', max: 'Old' }, DensitySize: 25 }
    ],
    resourceList: {
      enmatter: [''],
      ores: ['']
    },
    border: '26822,49002 27926,48970 29046,49002 29110,47834 28998,47786 29126,46858 28422,46666 27686,46826 27126,47066 27110,47898 26742,48394 26342,48602',
    addOffsetXY: true
  },
  { // ILLAWARRA 73 - OLA#29
    Name: 'ILLAWARRA 73 - OLA#29',
    id: 'ILLAWARRA',
    notes: '',
    huntingTax: 10.0,
    miningTax: 10.0,
    shoppingTax: 2.50,
    owner: 'Dervon Tas Cascade',
    feederPos: '31758,48228,174',
    mobsList: [
      { name: 'Exarosaur', maturity: { min: 'Young', max: 'Mature' }, DensitySize: 50 },
      { name: 'Merp', maturity: { min: 'Young', max: 'Mature' }, DensitySize: 45 }
    ],
    resourceList: {
      enmatter: ['Force', 'Lumis', 'Lytairian', 'Oil'],
      ores: ['Blausariam', 'Cumbriz', 'Dianum', 'Frigulite', 'Iron', 'Lysterium', 'Terrudite', 'Valurite']
    },
    border: '29494,48858 30022,48970 31206,49002 32262,48810 32502,48538 32518,47594 30726,47322 30342,46954 30006,47450 29286,47754 29222,48426',
    addOffsetXY: true
  },
  // Triton
  { // Pemphredo Island
    Name: 'Pemphredo Island',
    id: 'Pemphredo',
    notes: '',
    huntingTax: 3.99,
    miningTax: 3.99,
    shoppingTax: 2.50,
    owner: 'Gemini Nana Prudential',
    feederPos: '50906, 68515, 227',
    mobsList: [
      { name: 'Gokibusagi', maturity: { min: 'Guardian', max: 'Prowler' }, DensitySize: 50 },
      { name: 'Osseocollum', maturity: { min: 'Dominant', max: 'Prowler' }, DensitySize: 45 },
      { name: 'Scipulor', maturity: { min: 'Dominant', max: 'Prowler' }, DensitySize: 45 },
      { name: 'Sumima', maturity: { min: 'Dominant', max: 'Prowler' }, DensitySize: 45 },
      { name: 'Allophyl', maturity: { min: 'Dominant', max: 'Prowler' }, DensitySize: 45 },
      { name: 'Estophyl', maturity: { min: 'Dominant', max: 'Prowler' }, DensitySize: 45 },
    ],
    resourceList: {
      enmatter: ['Devil\'s Tail', 'Azur Pearls', 'Solis Beans', 'Binary Fluid', 'Cave Sap', 'Growth Molecules', 'Oil'],
      ores: ['Gold', 'Kanerium', 'Niksarium', 'Dianum', 'Ignisium', 'Durulium', 'Terrudite', 'Alferix']
    },
    border: '50212.82,69799.38 50448.61,69740.31 50684.4,69267.69 50831.77,69297.23 51008.61,69134.77 51082.3,68942.77 51259.14,68942.77 51229.66,69149.54 51406.51,69016.62 51671.77,68913.23 51907.56,68809.85 51966.51,68396.31 51686.51,67894.15 51686.51,67480.62 51937.04,67140.92 51214.93,66284.31 50610.71,66550.15 49579.13,66786.46 49490.71,67096.62 49623.34,68411.08 50035.97,69563.08',
    addOffsetXY: true
  },
  { // Deino Island
    Name: 'Deino Island',
    id: 'Deino',
    notes: 'EntropiaGold',
    huntingTax: 4.0,
    miningTax: 5.0,
    shoppingTax: 2.50,
    owner: 'Rachel MsPudding Hawkins',
    feederPos: '52783, 67816, 224',
    mobsList: [
      { name: 'Scipulor', maturity: { min: 'Young', max: 'Mature' }, DensitySize: 100 },
    ],
    resourceList: {
      enmatter: ['Devil\s Tail', 'Azur Pearls', 'Solis Beans', 'Binary Fluid', 'Cave Sap', 'Growth Molecules', 'Oil'],
      ores: ['Kanerium', 'Dianum', 'Ignisium', 'Durulium', 'Alferix', 'Terrudite', 'Lysterium', 'Gold']
    },
    border: '52054.93,68411.08 52290.72,68381.54 52629.67,68846.77 52673.88,69112.62 53204.41,69378.46 53631.78,69038.77 53440.2,68684.31 53779.15,68640 53852.84,67901.54 53690.73,67044.92 53307.57,66867.69 52806.52,66941.54 51995.98,67236.92 51804.4,67502.77 51863.35,67694.77 51789.67,67872 51922.3,68019.69',
    addOffsetXY: true
  },
  { // Enyo Island
    Name: 'Enyo Island',
    id: 'Enyo',
    notes: '',
    huntingTax: 3.99,
    miningTax: 3.99,
    shoppingTax: 2.50,
    owner: 'Gemini Nana Prudential',
    feederPos: '52052, 70228, 116',
    mobsList: [
      { name: 'Berycled', maturity: { min: 'Guardian', max: 'Old Alpha' }, DensitySize: 25 },
      { name: 'Sumima', maturity: { min: 'Dominant', max: 'Stalker' }, DensitySize: 20 },
      { name: 'Chirpy', maturity: { min: 'Young', max: 'Mature' }, DensitySize: 20 },
      { name: 'Gibnib', maturity: { min: 'Young', max: 'Mature' }, DensitySize: 20 },
      { name: 'Gokibusagi', maturity: { min: 'Alpha', max: 'Prowler' }, DensitySize: 15 },
      { name: 'Prancer', maturity: { min: 'Prowler', max: 'Stalker' }, DensitySize: 15 },
    ],
    resourceList: {
      enmatter: ['Devil\s Tail', 'Azur Pearls', 'Solis Beans', 'Binary Fluid', 'Cave Sap', 'Growth Molecules', 'Oil'],
      ores: ['Kanerium', 'Dianum', 'Ignisium', 'Durulium', 'Alferix', 'Terrudite', 'Lysterium', 'Gold']
    },
    border: '50360.19,70441.85 50758.08,70929.23 51185.45,70921.85 51509.67,70611.69 52054.93,70907.08 52747.57,70936.62 52902.31,71187.69 53359.15,71320.62 53668.63,70936.62 53580.2,70404.92 53948.63,69622.15 53801.26,69459.69 53329.68,69636.92 52843.36,69400.62 52592.83,69400.62 52489.67,69016.62 52047.56,68913.23 51413.88,69075.69 51310.72,69252.92 51178.09,69238.15 51163.35,69016.62 51074.93,69046.15 51074.93,69193.85 50839.14,69400.62 50677.03,69356.31 50352.82,69961.85 50308.61,70404.92',
    addOffsetXY: true
  },
  { // Naga Island
    Name: 'Naga Island',
    id: 'Naga',
    notes: '',
    huntingTax: 4.00,
    miningTax: 1.00,
    shoppingTax: 2.50,
    owner: 'Divinity Deth Undefined',
    feederPos: '50799, 72334, 106',
    mobsList: [
      { name: 'Sumima', maturity: { min: 'Young', max: 'Old' }, DensitySize: 99 },
    ],
    resourceList: {
      enmatter: ['Devil\s Tail', 'Azur Pearls', 'Solis Beans', 'Binary Fluid', 'Cave Sap', 'Growth Molecules', 'Oil'],
      ores: ['Kanerium', 'Dianum', 'Ignisium', 'Durulium', 'Alferix', 'Terrudite', 'Lysterium', 'Gold']
    },
    border: '49615.97,73462.15 50632.82,73639.38 51222.3,73565.54 51458.09,73432.62 52032.83,72324.92 52253.88,72221.54 52312.83,71985.23 52135.99,71645.54 52253.88,71084.31 51826.51,70951.38 51310.72,71364.92 50721.24,71468.31 49866.5,70848 49969.66,71571.69 49630.71,71867.08 49615.97,72265.85 49542.29,72605.54',
    addOffsetXY: true
  },
  { // Ayida Island
    Name: 'Ayida Island',
    id: 'Ayida',
    notes: 'Calypso Rescue Team',
    huntingTax: 2.0,
    miningTax: 2.0,
    shoppingTax: 2.50,
    owner: 'Lars Mr Lars Sebra',
    feederPos: '53557, 72464, 118',
    mobsList: [
      { name: 'Sumima', maturity: { min: 'Young', max: '' }, DensitySize: 15 },
      { name: 'Neconu', maturity: { min: 'Young', max: 'Mature' }, DensitySize: 25 },
      { name: 'Scipulor', maturity: { min: 'Young', max: '' }, DensitySize: 10 },
      { name: 'Estophyl (North West Beach)', maturity: { min: 'Dominant', max: 'Stalker' }, DensitySize: 20 },
    ],
    resourceList: {
      enmatter: ['Devil\s Tail', 'Azur Pearls', 'Solis Beans', 'Binary Fluid', 'Cave Sap', 'Growth Molecules', 'Oil'],
      ores: ['Kanerium', 'Dianum', 'Ignisium', 'Durulium', 'Alferix', 'Terrudite', 'Lysterium', 'Gold']
    },
    border: '52253.88,73609.85 53535.99,73565.54 53815.99,73373.54 54317.05,73299.69 54567.58,73211.08 54552.84,73019.08 54287.58,72812.31 54346.52,72162.46 54154.94,71483.08 53963.36,71409.23 53757.05,71512.62 53757.05,71763.69 53535.99,71896.62 53300.2,71808 53182.31,71453.54 52740.2,71335.38 52445.46,71424 52371.78,71822.77 52415.99,72088.62 51870.72,73019.08 52239.14,73565.54',
    addOffsetXY: true
  },
  { // Ouruboros Island
    Name: 'Ouruboros Island',
    id: 'Ouruboros',
    notes: '',
    huntingTax: 2.50,
    miningTax: 2.50,
    shoppingTax: 2.50,
    owner: 'Chad Sandman Dreamweaver',
    feederPos: '55569, 72126, 160',
    mobsList: [
      { name: 'Merp', maturity: { min: 'Young', max: 'Mature' }, DensitySize: 40 }
    ],
    resourceList: {
      enmatter: ['Devil\s Tail', 'Azur Pearls', 'Solis Beans', 'Binary Fluid', 'Cave Sap', 'Growth Molecules', 'Oil'],
      ores: ['Kanerium', 'Dianum', 'Ignisium', 'Durulium', 'Alferix', 'Terrudite', 'Lysterium', 'Gold']
    },
    border: '54523.37,72236.31 54877.05,72487.38 54936,72915.69 55599.16,73255.38 56011.8,73476.92 56527.59,73122.46 56851.8,72782.77 56984.43,72140.31 56512.85,71180.31 56114.95,71180.31 55392.85,71328 54921.26,70988.31 54685.47,71239.38 54390.73,71726.77 54479.16,72110.77',
    addOffsetXY: true
  },
  { // Bakunawa Island
    Name: 'Bakunawa Island',
    id: 'Bakunawa',
    notes: 'EntropiaFund',
    huntingTax: 4.20,
    miningTax: 3.50,
    shoppingTax: 2.50,
    owner: 'Toni Chiee Lahderinne',
    feederPos: '55734, 69358, 119',
    mobsList: [
      { name: 'Allophyl', maturity: { min: 'Young', max: 'Prowler' }, DensitySize: 40 },
      { name: 'Allophyl', maturity: { min: 'Alpha', max: 'Prowler' }, DensitySize: 20 },
      { name: 'Allophyl', maturity: { min: 'Dominant', max: 'Alpha' }, DensitySize: 10 },
      { name: 'Itumatrox', maturity: { min: 'Young', max: 'Mature' }, DensitySize: 15 }
    ],
    resourceList: {
      enmatter: ['Devil\s Tail', 'Azur Pearls', 'Solis Beans', 'Binary Fluid', 'Cave Sap', 'Growth Molecules', 'Oil'],
      ores: ['Kanerium', 'Dianum', 'Ignisium', 'Durulium', 'Alferix', 'Terrudite', 'Lysterium', 'Gold']
    },
    border: '54950.74,70028.31 55348.63,70338.46 56070.74,70530.46 56277.06,70441.85 56394.96,70249.85 56262.32,70013.54 56306.53,69865.85 56645.48,69910.15 56881.27,69747.69 56682.33,69408 56372.85,68787.69 56564.43,68566.15 56608.64,67975.38 56372.85,67960.62 55842.32,68182.15 55783.37,68654.77 55297.06,68832 55031.79,68640 54589.68,69038.77 54589.68,69319.38 54722.32,69437.54 55046.53,69289.85 55311.79,69363.69 55341.27,69540.92 54899.16,69806.77 54913.9,69998.77',
    addOffsetXY: true
  },
  { // Apophis Island
    Name: 'Apophis Island',
    id: 'Apophis',
    notes: '',
    huntingTax: 3.0,
    miningTax: 2.0,
    shoppingTax: 2.50,
    owner: 'Melissa Xrystine Stormbreaker',
    feederPos: '55723, 66974, 112',
    mobsList: [
      { name: 'Mermoth', maturity: { min: 'Young', max: 'Mature' }, DensitySize: 50 },
      { name: 'Hiryuu', maturity: { min: 'Young', max: 'Mature' }, DensitySize: 50 },
      { name: 'Neconu', maturity: { min: 'Young', max: 'Mature' }, DensitySize: 50 },
      { name: 'Estophyl', maturity: { min: 'Young', max: 'Mature' }, DensitySize: 50 },
      { name: 'Scipulor', maturity: { min: 'Young', max: 'Mature' }, DensitySize: 50 }
    ],
    resourceList: {
      enmatter: ['Devil\s Tail', 'Azur Pearls', 'Solis Beans', 'Binary Fluid', 'Cave Sap', 'Growth Molecules', 'Oil'],
      ores: ['Kanerium', 'Dianum', 'Ignisium', 'Durulium', 'Alferix', 'Terrudite', 'Lysterium', 'Gold']
    },
    border: '54972.84,68388.92 55282.32,68034.46 55709.69,67945.85 56387.59,67665.23 56638.12,67384.62 57080.22,66336 57050.75,66188.31 55945.48,66070.15 55562.32,65952 55031.79,65774.77 54663.37,65745.23 54368.63,66025.85 54353.89,66660.92 54825.47,67104 54250.73,67502.77 54206.52,67960.62 54427.58,68448 54633.89,68462.77 54796,67960.62 54913.9,68019.69 54899.16,68388.92',
    addOffsetXY: true
  },
];
