export default {
  i18n: {
    activeLocale: 'en',
    availableLocales: {
      en: {
        label: 'English',
        code: 'en'
      },
      se: {
        label: 'Svenska',
        code: 'sv'
      },
      jp: {
        label: '日本語',
        code: 'jp'
      }
    }
  },
  cookies: {
    intro: false,
    consent: false
  }
};
