import React, { useReducer } from 'react';
import { IAppAction, INITIAL_APP_STATE, appReducer } from '../reducers/AppReducer';

export interface IAppContext {
  app: {};
  dispatch: React.Dispatch<IAppAction>;
}

export const AppContext = React.createContext({
  app: INITIAL_APP_STATE,
  dispatch: (() => '') as React.Dispatch<IAppAction>
});

export const AppContextProvider: React.FC = ({ children }) => {
  const [app, dispatch] = useReducer(appReducer, INITIAL_APP_STATE);

  return <AppContext.Provider value={{ app, dispatch }}>{children}</AppContext.Provider>;
};
