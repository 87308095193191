import React, { } from 'react';
import styled from '@emotion/styled';

const Container = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'column',
  textAlign: 'left',
  marginTop: 25,
  marginBottom: 0,
});

export const MultiSelect = ({ children }: any) => {
  return (
    <Container>
      {children}
    </Container>
  );
};

