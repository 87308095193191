export enum Tools {
    DailyMission,
    Tools,
    EntropiaMapNew,
    TaxiService,
    RandomFun
}

export enum DailyMissionPlanet {
    Calypso,
    Cyrene
}