import React, { forwardRef, useMemo } from 'react';
import uniqid from 'uniqid';
import styled from '@emotion/styled';

interface IButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  disabled?: boolean;
  name?: string;
  value?: string;
  inline?: boolean;
  isSubmit?: boolean;
}

const Container = styled.div<{ inline: boolean }>(({ inline }) => ({
  marginTop: 20,
  marginBottom: 20,
  display: inline ? 'inline-block' : 'block'
}));

const ButtonContent = styled.button<{ disabled: boolean, inline: boolean }>(({ theme, disabled, inline }: any) => ({
  minWidth: 120,
  minHeight: 30,
  border: `1px solid ${theme.colors.grey}`,
  borderRadius: 10,
  backgroundColor: disabled ? theme.colors.grey : theme.colors.white,
  fontWeight: 900,
  fontSize: 16,
  padding: '5px 10px',
  color: theme.colors.lighter,
  opacity: disabled ? 0.7 : 1,
  boxShadow: '1px 1px 3px 0px grey',
  margin: inline ? '0px 10px 0px 10px' : 0,

  '&:hover': {
    boxShadow: `0px 0px 1px 1px ${theme.colors.white}`,
    outline: 'none',
    color: theme.colors.dark
  }
}));

export const ActionButton = forwardRef<HTMLButtonElement, IButtonProps>(({ isSubmit, disabled, value, name, children, inline = false, ...rest }, ref) => {
  const id = useMemo(uniqid, []);

  return (
    <Container inline={inline}>
      <ButtonContent
        id={id}
        disabled={disabled ? disabled : false}
        name={name}
        type={isSubmit ? 'submit' : 'button'}
        value={value}
        inline={inline}
        ref={ref}
        {...rest}
      >
        {children}
      </ButtonContent>
    </Container>
  );
});
