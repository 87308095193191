import React from 'react';

export const creatureNames = [
    'Aetherex',
    'Allophyl',
    'Ambulimax',
    'Araneatrox',
    'Argonaut',
    'Armax Bull',
    'Armax Cow',
    'Atrax',
    'Atrox',
    'Attacker',
    'Berycled',
    'Big Bulk',
    'Boorum Female',
    'Boorum Male',
    'Bristlehog',
    'Calamusoid',
    'Calypsocod',
    'Caperon',
    'Caudatergus',
    'Cersumon',
    'Chirin Dragon',
    'Chirpy',
    'Chomper',
    'Combibo',
    'Corinthian Kanin',
    'Cornoanterion',
    'Cornundacauda',
    'Cornundos',
    'Daikiba',
    'Daspletor',
    'Defender',
    'Diripi',
    'Droka',
    'Drone',
    'Drone Coordinator',
    'Equus',
    'Estophyl',
    'Eudorian Devil',
    'Eviscerator',
    'Exarosaur',
    'Falxangius',
    'Faucervix',
    'Feffoid',
    'Feffox',
    'Formicacida',
    'Formidon',
    'Foul',
    'Frescoquda',
    'Fugabarba',
    'Fungoid',
    'Furor',
    'Gibnib',
    'Globster',
    'Gokibusagi',
    'Gradivore',
    'Grand Formidon',
    'Harbinger',
    'Hiryuu',
    'Hispidus',
    'Hogglo',
    'Igni',
    'Itumatrox',
    'Kerberos',
    'Kingfisher',
    'Legionnaire',
    'Letomie',
    'Leviathan',
    'Longu',
    'Maffoid',
    'Malcruentor',
    'Marcimex',
    'Mermoth',
    'Merp',
    'Mind Reaver',
    'Miner Bot',
    'Molisk',
    'Morsusilex',
    'Mourner',
    'Mulaak\'f',
    'Mulmun',
    'Muluk-Hir',
    'Neconu',
    'Neomex',
    'Nexnecis',
    'Oculus',
    'Osseocollum',
    'Phasm',
    'Plumatergus',
    'Prancer',
    'Primordial Longu',
    'Proteron',
    'Prototype Defender',
    'Prototype Droka',
    'Prototype Drone',
    'Pupugi',
    'Razortooth',
    'Repesilex',
    'Rextelum',
    'Rippersnapper',
    'Sabakuma',
    'Scaboreas',
    'Scipulor',
    'Second Entity',
    'Shinkiba',
    'Snablesnot Female',
    'Snablesnot Male',
    'Snarg',
    'Snarksnot',
    'Sonic Squid',
    'Spider Bomber',
    'Spina Drone',
    'Spina Soldier',
    'Spina Worker',
    'Steel Bird',
    'Sumima',
    'Tantardion',
    'Tantillion',
    'Tezlapod',
    'Thorifoid Berserker',
    'Thorifoid Shaman',
    'Thorifoid Waywatcher',
    'Thorio Female',
    'Thorio Male',
    'Traeskeron',
    'Trilomite',
    'Tripudion',
    'Trooper',
    'TskTsk',
    'Turp',
    'Vanguard',
    'Warlock',
    'Warrior',
];

interface Creature {
    Name: string,
    Maturities: [CreatureMaturity],
    Loot: [CreatureLoot],
    DamageTypes: [string]
}

interface CreatureMaturity {
    Maturity: string,
    Health: string,
    RegenSpeed: string,
    Damage: string,
    DangerLevel: string,
    HpPerLevel: string,
}

interface CreatureLoot {
    Name: string,
    Type: string,
    Frequency: string,
    Maturity: string,
    Value: number
}

interface DamageTypes {
    Stab: number,
    Cut: number,
    Impact: number,
    Penetration: number,
    Shrapnel: number,
    Burn: number,
    Cold: number,
    Acid: number,
    Electric: number
}

export const creatures = [
    {
        Name: "Aetherex",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            },
            {
                Maturity: "Mature",
                Health: "650",
                RegenSpeed: "0.2",
                Damage: "N/A",
                DangerLevel: "13",
                HpPerLevel: "50"
            },
            {
                Maturity: "Old",
                Health: "710",
                RegenSpeed: "0.29",
                Damage: "68",
                DangerLevel: "18",
                HpPerLevel: "39.4"
            },
            {
                Maturity: "Provider",
                Health: "750",
                RegenSpeed: "0.29",
                Damage: "72",
                DangerLevel: "16",
                HpPerLevel: "46.9"
            },
            {
                Maturity: "Guardian",
                Health: "760",
                RegenSpeed: "0.39",
                Damage: "80",
                DangerLevel: "17",
                HpPerLevel: "44.7"
            },
            {
                Maturity: "Dominant",
                Health: "800",
                RegenSpeed: "N/A",
                Damage: "N/A",
                DangerLevel: "18",
                HpPerLevel: "44.4"
            },
            {
                Maturity: "Alpha",
                Health: "830",
                RegenSpeed: "N/A",
                Damage: "N/A",
                DangerLevel: "19",
                HpPerLevel: "43.7"
            },
            {
                Maturity: "Old Alpha",
                Health: "900",
                RegenSpeed: "N/A",
                Damage: "98",
                DangerLevel: "22",
                HpPerLevel: "40.9"
            },
            {
                Maturity: "Prowler",
                Health: "940",
                RegenSpeed: "N/A",
                Damage: "116",
                DangerLevel: "24",
                HpPerLevel: "39.2"
            },
            {
                Maturity: "Stalker",
                Health: "1000",
                RegenSpeed: "N/A",
                Damage: "121",
                DangerLevel: "25",
                HpPerLevel: "40"
            }
        ],
        Loot: [
            {
                Name: "Animal Thyroid Oil",
                Type: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.1"
            },
            {
                Name: "Aetherex Skin",
                Type: "Natural Material",
                Frequency: "Uncommon",
                Maturity: "Young",
                Value: "4.70"
            },
            {
                Name: "Soft Hide",
                Type: "Natural Material",
                Frequency: "Common",
                Maturity: "Young",
                Value: "0.1"
            },
            {
                Name: "Surface Hardener Component",
                Type: "Enhancer Component",
                Frequency: "Common",
                Maturity: "Young",
                Value: "0.2"
            },
            {
                Name: "Diluted Sweat",
                Type: "Natural Material",
                Frequency: "Common",
                Maturity: "Young",
                Value: "0.01"
            },
            {
                Name: "Second-Rate Cloth Extractor",
                Type: "Texture Extractor",
                Frequency: "Common",
                Maturity: "Young",
                Value: "0.01"
            },
            {
                Name: "Shrapnel",
                Type: "Fragment",
                Frequency: "Very Often",
                Maturity: "Young",
                Value: "0.0001"
            },
            {
                Name: "Chronicle Shin Guards",
                Type: "Armor",
                Frequency: "Extremely Rare",
                Maturity: "Unknown",
                Value: "230"
            }
        ],
        DamageTypes: {
            Impact: 100
        }
    },
    {
        Name: "Allophyl",
        Maturities: [
            {
                Maturity: "Young",
                Health: "500",
                RegenSpeed: "5",
                Damage: "40",
                DangerLevel: "14",
                HpPerLevel: "35.7"
            },
            {
                Maturity: "Mature",
                Health: "560",
                RegenSpeed: "5.6",
                Damage: "42",
                DangerLevel: "16",
                HpPerLevel: "35"
            },
            {
                Maturity: "Old",
                Health: "660",
                RegenSpeed: "5.5",
                Damage: "50",
                DangerLevel: "18",
                HpPerLevel: "36.7"
            },
            {
                Maturity: "Provider",
                Health: "710",
                RegenSpeed: "5.55",
                Damage: "42",
                DangerLevel: "19",
                HpPerLevel: "37.4"
            },
            {
                Maturity: "Guardian",
                Health: "790",
                RegenSpeed: "6.22",
                Damage: "47",
                DangerLevel: "22",
                HpPerLevel: "35.9"
            },
            {
                Maturity: "Dominant",
                Health: "830",
                RegenSpeed: "8.3",
                Damage: "50",
                DangerLevel: "23",
                HpPerLevel: "36.1"
            },
            {
                Maturity: "Alpha",
                Health: "900",
                RegenSpeed: "9",
                Damage: "69",
                DangerLevel: "24",
                HpPerLevel: "37.5"
            },
            {
                Maturity: "Old Alpha",
                Health: "950",
                RegenSpeed: "9.5",
                Damage: "75",
                DangerLevel: "26",
                HpPerLevel: "36.5"
            },
            {
                Maturity: "Prowler",
                Health: "1040",
                RegenSpeed: "10.4",
                Damage: "83",
                DangerLevel: "28",
                HpPerLevel: "37.1"
            },
            {
                Maturity: "Stalker",
                Health: "1170",
                RegenSpeed: "11.7",
                Damage: "99",
                DangerLevel: "30",
                HpPerLevel: "39"
            }
        ],
        Loot: [
            {
                Name: "Allophyl Skin",
                Type: "Oil",
                Frequency: "Common",
                Maturity: "Young",
                Value: "0.04"
            },
            {
                Name: "Animal Hide",
                Type: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            },
            {
                Name: "DNA Fragment A",
                Type: "Oil",
                Frequency: "Uncommon",
                Maturity: "Young",
                Value: "0.04"
            },
            {
                Name: "Animal Eye Oil",
                Type: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            },
            {
                Name: "Paint Can (Brown)",
                Type: "Oil",
                Frequency: "Common",
                Maturity: "Young",
                Value: "0.04"
            },
            {
                Name: "Socket 4 Component",
                Type: "Oil",
                Frequency: "Common",
                Maturity: "Young",
                Value: "0.04"
            },
            {
                Name: "Socket 3 Component",
                Type: "Oil",
                Frequency: "Common",
                Maturity: "Young",
                Value: "0.04"
            },
            {
                Name: "Diluted Sweat",
                Type: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            },
            {
                Name: "Focus Lens Component",
                Type: "Oil",
                Frequency: "Common",
                Maturity: "Young",
                Value: "0.04"
            },
            {
                Name: "Basic Stone Extractor",
                Type: "Oil",
                Frequency: "Common",
                Maturity: "Young",
                Value: "0.04"
            },
            {
                Name: "Advanced Adaptive Fuse",
                Type: "Oil",
                Frequency: "Uncommon",
                Maturity: "Young",
                Value: "0.04"
            },
            {
                Name: "Shrapnel",
                Type: "Oil",
                Frequency: "Very often",
                Maturity: "Young",
                Value: "0.04"
            },
            {
                Name: "Paint Can (Olive)",
                Type: "Oil",
                Frequency: "Common",
                Maturity: "Young",
                Value: "0.04"
            },
            {
                Name: "Basic Cloth Extractor",
                Type: "Oil",
                Frequency: "Common",
                Maturity: "Young",
                Value: "0.04"
            },
            {
                Name: "Animal Muscle Oil",
                Type: "Oil",
                Frequency: "Very often",
                Maturity: "Young",
                Value: "0.04"
            },
            {
                Name: "Bear Foot Guards (L)",
                Type: "Oil",
                Frequency: "Rare",
                Maturity: "Young",
                Value: "0.04"
            },
            {
                Name: "Aurora Thigh Guards (L)",
                Type: "Oil",
                Frequency: "Rare",
                Maturity: "Young",
                Value: "0.04"
            },
            {
                Name: "Aurora Harness (L)",
                Type: "Oil",
                Frequency: "Rare",
                Maturity: "Young",
                Value: "0.04"
            },
            {
                Name: "NeoPsion 10-A Mindforce Implant (L)",
                Type: "Oil",
                Frequency: "Uncommon",
                Maturity: "Young",
                Value: "0.04"
            },
            {
                Name: "Empty Skill Implant (L)",
                Type: "Oil",
                Frequency: "Rare",
                Maturity: "Young",
                Value: "0.04"
            },
            {
                Name: "Lacerating Attack Nanochip 4 (L)",
                Type: "Oil",
                Frequency: "Uncommon",
                Maturity: "Young",
                Value: "0.04"
            },
            {
                Name: "Potted Medium Cactus",
                Type: "Oil",
                Frequency: "Uncommon",
                Maturity: "Young",
                Value: "0.04"
            },
            {
                Name: "Calypso Bone Sample",
                Type: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            },
            {
                Name: "Omegaton A101",
                Type: "Oil",
                Frequency: "Extremely rare",
                Maturity: "Young",
                Value: "0.04"
            },
            {
                Name: "Aurora Helmet (L)",
                Type: "Oil",
                Frequency: "Uncommon",
                Maturity: "Young",
                Value: "0.04"
            },
            {
                Name: "NeoPsion 20 Mindforce Implant",
                Type: "Oil",
                Frequency: "Uncommon",
                Maturity: "Young",
                Value: "0.04"
            },
            {
                Name: "Aurora Shin Guards (L)",
                Type: "Oil",
                Frequency: "Uncommon",
                Maturity: "Young",
                Value: "0.04"
            },
            {
                Name: "Aurora Foot Guards (L)",
                Type: "Oil",
                Frequency: "Rare",
                Maturity: "Young",
                Value: "0.04"
            },
            {
                Name: "NeoPsion 30 Mindforce Implant",
                Type: "Oil",
                Frequency: "Rare",
                Maturity: "Young",
                Value: "0.04"
            },
            {
                Name: "Aurora Arm Guards (L)",
                Type: "Oil",
                Frequency: "Uncommon",
                Maturity: "Young",
                Value: "0.04"
            },
            {
                Name: "Shopkeeper Pad",
                Type: "Oil",
                Frequency: "Extremely rare",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: {
            Impact: 33,
            Electric: 67
        }
    },
    {
        Name: "Ambulimax",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Type: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Araneatrox",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Type: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Argonaut",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Armax Bull",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Armax Cow",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Atrax",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Atrox",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Attacker",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Berycled",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Big Bulk",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Boorum Female",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Boorum Male",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Bristlehog",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Calamusoid",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Calypsocod",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Caperon",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Caudatergus",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Cersumon",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Chirin Dragon",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Chirpy",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Chomper",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Combibo",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Corinthian Kanin",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Cornoanterion",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Cornundacauda",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Cornundos",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Daikiba",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Daspletor",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Defender",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Diripi",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Droka",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Drone",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Drone Coordinator",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Eomon",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Equus",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Estophyl",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Eudorian Devil",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Eviscerator",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Exarosaur",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Falxanigus",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Faucervix",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Feffoid",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Feffox",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Formicacida",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Formidon",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Foul",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Frescoquda",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Fugabarba",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Fungoid",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Furor",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Gibnib",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Globster",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Gokibusagi",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Gradivore",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Grand Formidon",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Harbinger",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Hiryuu",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Hispidus",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Hogglo",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Igni",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Itumatrox",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Kerberos",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Kingfisher",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Legionnaire",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Letomie",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Leviathan",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Longtooth",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Longu",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Maffoid",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Malcruentor",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Marcimex",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Mermoth",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Merp",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Mind Reaver",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Miner Bot",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Molisk",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Morsusilex",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Mourner",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Mulaak'f",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Mulmun",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Mukluk-Hir",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Neconu",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Neomex",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Nexnecis",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Oculus",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Osseocollum",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Phasm",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Plumatergus",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Prancer",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Primordial Longu",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Proteron",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Prototype Defender",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Prototype Droka",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Prototype Drone",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Pupugi",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Razortooth",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Repesilex",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Rextelum",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Rippersnapper",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Sabakuma",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Scaboreas",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Scipulor",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Second Entity",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Shinkiba",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Snablesnot Female",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Snablesnot Male",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Snarg",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Snarksnot",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Sonic Squid",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Spider Bomber",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Spina Drone",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Spina Soldier",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Spina Worker",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Steel Bird",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Sumima",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Tantardion",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Tantillion",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Tezlapod",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Thorifoid Berserker",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Thorifoid Shaman",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Thorifoid Waywatcher",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Thorio Female",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Thorio Male",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Traeskeron",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Trilomite",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Tripudion",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Trooper",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "TskTsk",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Turp",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Vanguard",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Warlock",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    },
    {
        Name: "Warrior",
        Maturities: [
            {
                Maturity: "Young",
                Health: "600",
                RegenSpeed: "N/A",
                Damage: "57",
                DangerLevel: "13",
                HpPerLevel: "46.2"
            }
        ],
        Loot: [
            {
                Name: "Oil",
                Frequency: "Often",
                Maturity: "Young",
                Value: "0.04"
            }
        ],
        DamageTypes: ""
    }
];

export const generateCreatureList = () => {
    const isTesting = false;
    let areaNames: string[] = [];

    creatureNames.forEach(x => areaNames.push(x));

    let sortedNames = areaNames.sort((a, b) => a.localeCompare(b));

    let names = sortedNames.map(i => <option key={i} value={i}>{i}</option>);

    if (names.length > 0) {
        if (isTesting) {
            names.unshift(<option key='All' value={'all'}>All</option>);
        }
        names.unshift(<option key='None' value={''}>None</option>);
    }

    return new Set(names);
}