import React from 'react';

interface ILocale {
  label: string;
  code: string;
}

interface ILocales {
  [key: string]: ILocale;
}

export interface ILocaleContext {
  activeLocale: string;
  locales: ILocales;
  changeLocale?: (locale: string) => void;
}

export const LocaleContext = React.createContext<ILocaleContext>({
  activeLocale: 'en',
  locales: {
    en: {
      label: 'English',
      code: 'en'
    }
  }
});
