const maxViewBoxSize = 768;
const pixelToEuX = 95.79;
const pixelToEuY = -96;
const minEuX = 16480;
const minEuY = 98304;

export const getMapCoordsXFromEUCoords = (coordinateX: number) => {
    return (coordinateX - minEuX) / pixelToEuX;
}

export const getMapCoordsYFromEUCoords = (coordinateY: number) => {
    return (coordinateY - minEuY) / pixelToEuY;
}

export const getMapPointsFromEUCoords = (polygonPoints: string, addOffset?: boolean) => {
    var arr = polygonPoints.split(" ");
    var mapPoints = "";

    arr.forEach(i => {
        var positions = i.split(",");
        var x = positions[0] as unknown as number;
        var y = positions[1] as unknown as number;

        var xAsPoint = getMapCoordsXFromEUCoords(x);
        var yAsPoint = getMapCoordsYFromEUCoords(y);

        // TODO: Add this to border & spawn positions instead
        if (addOffset) {
            xAsPoint += 1;
            yAsPoint += 0.2;
        }

        var newPoint = xAsPoint + "," + yAsPoint + " ";
        mapPoints += newPoint;
    });

    return mapPoints;
}

export const getZoomScaleFactorForPos = (rectWidth: number) => {
    return (rectWidth / maxViewBoxSize);
}
