import React, { forwardRef, useMemo } from 'react';
import uniqid from 'uniqid';
import styled from '@emotion/styled';

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'left',
  marginTop: 15,
  marginBottom: 5
});

const Select = styled.select(({ theme }: any) => ({
  minHeight: 36,
  maxWidth: '100%',
  minWidth: 150,
  border: `1px solid ${theme.colors.grey}`,
  borderRadius: 5,
  padding: '0 5px',
  margin: '0 10px',
  boxShadow: '1px 1px 3px 0px grey',

  '&:focus': {
    boxShadow: `0px 0px 1px 1px ${theme.colors.gold}`,
    outline: 'none'
  },

  [theme.breakpoints.mobileAndLower]: {
    marginRight: 5,
    minWidth: 100
  }
}));

const Label = styled.label<{ disabled?: boolean }>(({ disabled }) => ({
  fontSize: 16,
  marginLeft: 15,
  opacity: disabled ? 0.5 : 1
}));

interface ISelectProps extends React.HTMLAttributes<HTMLSelectElement> {
  label?: string;
  disabled?: boolean;
  name?: string;
  required?: boolean;
  value?: string;
  placeholder?: string;
}

export const Dropdown = forwardRef<HTMLSelectElement, ISelectProps>(
  ({ label, disabled, name, required, value, placeholder, children, ...rest }, ref) => {
    const id = useMemo(uniqid, []);

    return (
      <Container>
        {label && (
          <Label disabled={disabled} htmlFor={id}>
            {label}:
          </Label>
        )}
        <Select id={id} defaultValue={placeholder} disabled={disabled} name={name} required={required} value={value} ref={ref} {...rest}>
          {placeholder && <option disabled>{placeholder}</option>}
          {children}
        </Select>
      </Container>
    );
  }
);
