export const pvpZonesList = [
    {
        id: 1,
        name: 'EudoriaPVP',
        deadly: false,
        pos: '76320,93616 77520,93632 78728,96224 80832,96224 81824,94320 80128,90912 76320,90912 75632,92336'
    },
    {
        id: 2,
        name: 'EudoriaContaminated',
        deadly: true,
        pos: '74912,88832 77344,88832 78048,87104 80608,87104 81728,84832 80608,82592 74944,82592 74112,84864'
    },
    {
        id: 3,
        name: 'AkmuulContaminated',
        deadly: true,
        pos: '25168,29616 28128,29216 28656,28672 28976,27776 29872,28752 30560,28000 30304,27552 30496,27088 30432,25824 29760,25152 27536,24752 26368,24752 25248,25136 25456,27168'
    }
];